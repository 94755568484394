import React, {
  Context,
  createContext,
  useState,
  FC,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { login, godmodLogin, eagleLogin } from "../../api";
import { queryBrandInfo, brandInfoType } from "../../api"; // 24.11.01 SCH ★
import { getMe, meDataType, updateMe } from "../../api/me";
import { useLiff } from "../Liff";
import { useAppStateStore } from "../../store/appState";

//-----------  type / interface ----------------------------------------
interface AuthContext {
  error?: any;
  isLoggedIn: boolean;
  logging: boolean;
  isMember: boolean;
  isNormal: boolean;
  accessToken: string | null | undefined;
  user?: UserData;
  auth: (redirect?: string) => void;
  fetching: boolean;
  queryMe?: () => Promise<void>;
}

interface AuthProviderProps {
  lineToken: string;
  children?: React.ReactNode;
}

type CreateAuthContextType = () => {
  // AuthConsumer: Consumer<AuthContext>;
  AuthProvider: FC<AuthProviderProps>;
  useAuth: () => AuthContext;
};

interface UserData extends Partial<meDataType> {
  isLoggedIn?: boolean;
  isMember?: boolean;
  isNormal?: boolean;
  accessToken?: string | null;
  line_id?: string;
  mobile_number?: string | null;
  email?: string | null;
  promo_hash?: string;
  promo_slug?: string | null;
}

//-----------------------------------------------------------------------

const createAuthProvider = (context: Context<AuthContext>) => {
  const AuthProvider: FC<AuthProviderProps> = ({ children, lineToken }) => {
    const { idToken, liff } = useLiff();
    const [error, setError] = useState<any>();
    const [accessToken, setAccessToken] = useState<string>();
    const [logging, setLogging] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isNormal, setIsNormal] = useState<boolean>(false);
    const [isMember, setIsMember] = useState<boolean>(false);
    const [user, setUser] = useState<UserData>();
    const [fetching, setFetching] = useState<boolean>(false);
    const navigate = useNavigate();
    const { APP, BRAND, USER } = useAppStateStore(); // 24.11.01 SCH ★
    const {
      setAppState,
      appState,
      liffState,
      setIpFlowState,
      setLiffState,
      ipFlowState,
    } = APP;
    const { brandState, setBrandState } = BRAND; // 24.11.01 SCH ★
    const { userState, setUserState } = USER;

    //   query eagleLogin url

    /**
     * 判斷一個字串是否為有效的數字
     * @param inputs 要驗證的字串
     */
    function isNumber(inputs: string) {
      return !isNaN(parseFloat(inputs)); // 更新為使用 isNaN 函式來判斷
    }

    /**
     * 簡易驗證是否為有效的台灣手機號碼
     * @param value 手機號碼
     */
    const validatePhone = (value: string) => {
      return (
        value &&
        value.length === 10 &&
        value[0] === "0" &&
        value[1] === "9" &&
        isNumber(value)
      );
    };

    /**
     * 會員登入函式，用於驗證手機號碼是否有，無效則條轉至首頁。
     * @param _eaglePromoterMobileNumber 推廣人員手機號碼
     */
    const validateAndNavigateToHome = (_eaglePromoterMobileNumber: string) => {
      const isValidtePhone = validatePhone(_eaglePromoterMobileNumber);

      if (!isValidtePhone) {
        navigate("/");
        return;
      }
    };

    // 取得當前頁面的路徑資訊
    const location = useLocation();
    // 從路徑資訊中取得查詢參數
    const searchParams = new URLSearchParams(location.search);
    // 從查詢參數中獲取名為 'mobile_number' 的值
    const eaglePromoterMobileNumber = searchParams.get("mobile_number");
    // 從查詢參數中獲取名為 'couponCode' 的值
    const couponCode = searchParams.get("couponCode");
    // 檢查是否存在 'mobile_number' 的值，如果有值則設定為 true，否則設定為 false
    const isEaglePromoter = eaglePromoterMobileNumber ? true : false;
    const promo_hash = searchParams.get("ph");
    const promo_slug = searchParams.get("ps");
    const isPromoHash = promo_hash ? true : false;
    const isPromoSlug = promo_slug ? true : false;

    const auth = async (redirect?: string) => {
      if (!liffState.lineIDToken) return;
      try {
        let response;
        setAppState({ isLoading: true });

        if (isEaglePromoter) {
          setIpFlowState({ isEagle: true });
          response = await eagleLogin(
            1,
            liffState.lineIDToken,
            eaglePromoterMobileNumber as string,
            1
          );
        } else {
          if (isPromoSlug && promo_slug) {
            response = await login(
              1,
              liffState.lineIDToken,
              undefined,
              promo_slug
            );
          } else if (isPromoHash && promo_hash) {
            response = await login(
              1,
              liffState.lineIDToken,
              promo_hash,
              undefined
            );
          } else {
            response = await login(1, liffState.lineIDToken);
          }
        }

        // 正式機老鷹推廣會員的GODMode
        // dev測試機人員內部的GodMode // 24.10.29 SCH (下面這八行要啟用, 平常要備註掉) ★★★ (ngork)
        // const { error: loginError, data: res } = await godmodLogin(1, "7u8i9o0p","0970980199");
        // if (isPromoHash && promo_hash) {
        //   response = await godmodLogin(1, "7u8i9o0p", "0970980199", promo_hash);
        // } else if (isPromoSlug && promo_slug) {
        //   response = await godmodLogin( 1,"7u8i9o0p","0970980199", undefined,promo_slug);
        // } else {
        //   response = await godmodLogin(1, "7u8i9o0p", "0970980199");
        // }
        // dev測試機人員內部的GodMode // 24.10.29 SCH (下面這一行要停用, 平常是啟用的) ★★★ (ngork)
        const { error: loginError, data: res } = response;
        // dev測試機人員內部的GODMode
        // const { error: loginError, data: res } = await godmodLogin(1, 'qwertyuiop', '0970980199');

        if (loginError) {
          if (loginError.response?.status === 401) {
            console.log("loginError", loginError);

            // setError(loginError);
            // setIsLoggedIn(false);
            // setUser((pre) => ({
            //   ...pre,
            //   accessToken: undefined,
            //   isLoggedIn: false,
            //   isMember: false,
            //   isNormal: false,
            // }));
            setAppState({
              isLoading: false,
              isError: true,
              errorCode: 401,
              errorMsg: loginError.message,
            });
            setBrandState({
              accessToken: null, // 24.11.01 SCH ★
            });
            setUserState({
              accessToken: null,
            });
            setIpFlowState({ isLogin: false });

            setLiffState({ lineIsLoggedIn: false });
            liff.logout();
            window.location.reload();
            return;
          } else if (loginError.response?.status === 404) {
            // setIsLoggedIn(false);
            // setIsNormal(false);
            // setIsMember(false);
            // setUser((pre) => ({
            //   ...pre,
            //   accessToken: undefined,
            //   isLoggedIn: false,
            //   isMember: false,
            //   isNormal: false,
            // }));
            setAppState({
              isLoading: false,
              isError: true,
              errorCode: 404,
              errorMsg: loginError.message,
            });
            setBrandState({
              accessToken: null, // 24.11.01 SCH ★
            });
            setUserState({
              accessToken: null,
            });
            setIpFlowState({ isLogin: false });
          } else {
            setAppState({
              isLoading: false,
              isError: true,
              errorCode: 404,
              errorMsg: loginError.message,
            });
            setBrandState({
              accessToken: null, // 24.11.01 SCH ★
            });
            setUserState({
              accessToken: null,
            });
            setIpFlowState({ isLogin: false });

            // setError(loginError);
            // setIsLoggedIn(false);
            // setUser((pre) => ({
            //   ...pre,
            //   accessToken: undefined,
            //   isLoggedIn: false,
            //   isMember: false,
            //   isNormal: false,
            // }));
          }
          return;
        }
        if (res) {
          const { data } = res;

          const brandInfo = await getBrandInfo(data.token);
          console.log("brandInfo", brandInfo); // 24.11.06 SCH ★
          console.log("name_card_page_1", brandInfo?.name_card_page_1); // 25.01.28 SCH ★
          console.log("name_card_page_2", brandInfo?.name_card_page_2); // 25.01.28 SCH ★
          setBrandState({
            ...brandInfo,
            accessToken: data.token,
          });

          const meData = await getMeData(data.token);
          console.log("meData", meData); // 25.01.28 SCH ★
          console.log(
            "[role, eagle, enable, editable]",
            meData?.role,
            meData?.eagle,
            meData?.enable,
            meData?.editable
          ); // 25.01.28 SCH ★
          setUserState({
            ...meData,
            accessToken: data.token,
          });
          setAppState({ isLoading: false, isError: false });
        }
      } catch (error) {
        console.log(error);

        setAppState({
          isLoading: false,
          isError: true,
        });
        setBrandState({
          accessToken: null, // 24.11.01 SCH ★
        });
        setUserState({
          accessToken: null,
        });
        setIpFlowState({ isLogin: false });
      }
      if (redirect) {
        navigate(redirect);
      }
    };

    // 24.11.01 SCH ★
    const getBrandInfo = async (token: string) => {
      try {
        const { data: res } = await queryBrandInfo(token);
        if (res) {
          const { data } = res;
          return data;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    };

    const getMeData = async (token: string) => {
      try {
        const { data: res } = await getMe(token);
        if (res) {
          const { data } = res;
          return data;
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    };

    const fetchMe = useCallback(async (token: string) => {
      try {
        setFetching(true);
        const resp = await getMe(token);

        setUser((prev) => ({ ...prev, ...resp.data }));
        setFetching(false);
      } catch (error) {
        console.log(error);
        setFetching(false);
      }
    }, []);

    const updateMeData = async ({ coupon_code }: { coupon_code: string }) => {
      if (userState.accessToken) {
        setAppState({ isLoading: true });
        try {
          const { data: res, error: meError } = await updateMe(
            userState.accessToken,
            {
              coupon_code: coupon_code,
            }
          );
          if (res) {
            const { data } = res;
            if (res) {
              const { data: updateMeResData } = data;
              setUserState({
                ...updateMeResData,
                user_id: updateMeResData.id,
              });
            }
          }
          setAppState({ isLoading: false });
        } catch (error) {
          console.log(error);
          setAppState({ isLoading: false });
        }
      }
    };

    useEffect(() => {
      console.log("user", user);
    }, [user]);

    const queryMe = useCallback(
      () => fetchMe(user?.accessToken!),
      [fetchMe, user?.accessToken]
    );

    useEffect(() => {
      if (lineToken) {
        (async () => {
          // setLogging(true);
          setAppState({ isLoading: true });
          if (isEaglePromoter) {
            validateAndNavigateToHome(
              eaglePromoterMobileNumber ? eaglePromoterMobileNumber : ""
            );
          }
          await auth();
          setAppState({ isLoading: false });
          // setLogging(false);
        })();
      }
      // eslint-disable-next-line
    }, [lineToken]);

    useEffect(() => {
      if (!couponCode) return;
      console.log(`優惠碼：${couponCode}`);
      setUserState({ ...userState, coupon_code: couponCode });
      updateMeData({ coupon_code: couponCode });
    }, [couponCode, userState.accessToken]);

    return (
      <context.Provider
        value={{
          error,
          logging,
          auth,
          accessToken: accessToken,
          isLoggedIn,
          isMember,
          isNormal,
          fetching,
          user,
          queryMe: user ? queryMe : undefined,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  return AuthProvider;
};

export const CreateAuthContext: CreateAuthContextType = () => {
  // @ts-ignore
  const context = createContext<AuthContext>({
    isLoggedIn: false,
    accessToken: undefined,
    logging: true,
    isMember: false,
    isNormal: false,
    auth: (redirect?: string) => {},
  });
  context.displayName = "AuthContext";

  return {
    AuthProvider: createAuthProvider(context),
    useAuth: () => useContext(context),
  };
};

export const { AuthProvider, useAuth } = CreateAuthContext();
