import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { INameCardType } from "../../../interface/nameCard";
import classes from "../Footer.module.sass";
import { addNameCards, updateNameCards } from "../../../api";
import { CFlexBubble } from "../../../../flexMessageType";
import {
  IFlexMsgContent,
  useShareFlexMsg,
} from "../../../hooks/useShareFlexMsg";
import { useAppStateStore } from "../../../store/appState";
import { hasImageInThisNameCard } from "../../../utils/hasImageInThisNameCard";
import {
  fullImgModeNameCardButtonLabelConfig,
  fullImgModeNameCardButtonType,
} from "../../../config/fullImgModeNameCardConfig";

const CreateNameCardFullImgModeFooter = () => {
  const { APP, NAMECARD_DATA, USER } = useAppStateStore();
  const { currentConfig } = APP;
  const { accessToken } = USER.userState;
  const { setNameCardV3CurrentPage, nameCardData } = NAMECARD_DATA;
  const {
    nameCardV3Data,
    nameCardV3Setting,
    currentCategory,
    nameCardV3Form,
    nameCardV3CurrentPage,
  } = nameCardData;
  const { altText, cardTitle } = nameCardV3Setting;
  const navigate = useNavigate();
  const { user_category_id, user_folder_id, category_name, folder_name } =
    currentCategory;

  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: {
    _flexMsgContent: IFlexMsgContent;
    _message_text: string;
  } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "fullImgModeFlexMsg" },
      currentContent: nameCardV3Data,
    },
    _message_text: altText,
  };

  /**
   * 發建立數位名片 api
   * @param _accessToken //會員專屬的 accessToken
   */
  const AddNameCard = async (
    _accessToken: string,
    _flexMsg: CFlexBubble[],
    _title: string,
    _message_text: string,
    _card_type: 1 | 2
  ) => {
    const removeS3BaseUrl = (content: CFlexBubble[]) => {
      const temp: CFlexBubble[] = JSON.parse(JSON.stringify(content));

      const result = temp.map((msg) => {
        if (msg.body?.contents && msg.body?.contents.length > 0) {
          if (
            msg.body?.contents[0].type === "image" &&
            msg.body?.contents[0].url.indexOf(
              currentConfig.REACT_APP_IMAGEBASEURL
            ) !== -1
          ) {
            (msg.body?.contents[0]).url = msg.body?.contents[0].url.split(
              currentConfig.REACT_APP_IMAGEBASEURL
            )[1];
            return msg; // 返回修改後的 msg
          } else {
            return msg; // 返回原始的 msg
          }
        } else {
          return msg;
        }
      });
      return result;
    };
    console.log(
      "remove",
      removeS3BaseUrl(JSON.parse(JSON.stringify(_flexMsg)))
    );

    const momileNumberAddTel = (content: CFlexBubble[]): CFlexBubble[] => {
      return content.map((cflexBubble) => {
        if (cflexBubble.body && cflexBubble.body.contents) {
          cflexBubble.body.contents = cflexBubble.body.contents.map(
            (flexComponent) => {
              if (
                flexComponent.type === "box" &&
                flexComponent.action &&
                flexComponent.action.type === "uri" &&
                flexComponent.action.label ===
                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                flexComponent.contents &&
                flexComponent.contents.length > 0 &&
                flexComponent.contents[0].type === "text" &&
                flexComponent.contents[0].text ===
                  fullImgModeNameCardButtonType.callphoneButton.text
              ) {
                // 只有在 uri 不是以 "tel:+886" 開頭時才添加前綴
                if (!flexComponent.action.uri.startsWith("tel:+886")) {
                  flexComponent.action.uri =
                    "tel:+886" + flexComponent.action.uri;
                }
              }
              return flexComponent;
            }
          );
        }
        return cflexBubble;
      });
    };

    // const _cardContent: CFlexBubble[] = _flexMsg;
    const hasRemoveImageBaseurlCardContent: CFlexBubble[] = removeS3BaseUrl(
      JSON.parse(JSON.stringify(_flexMsg))
    );
    const resultNameCardContent: CFlexBubble[] = momileNumberAddTel(
      JSON.parse(JSON.stringify(hasRemoveImageBaseurlCardContent))
    );

    const getImgUrls = (content: CFlexBubble[]) => {
      const imageUrls: string[] = [];
      content.forEach((message) => {
        if (message.body?.contents && message.body.contents.length > 0) {
          if (
            message.body?.contents[0].type === "image" &&
            message.body?.contents[0].url.indexOf(
              currentConfig.REACT_APP_IMAGEPATH
            ) !== -1
          ) {
            imageUrls.push((message.body?.contents[0]).url);
          }
        }
      });
      console.log("imageUrls", imageUrls);
      return imageUrls;
    };

    const nameCardContent: INameCardType = {
      card_type: _card_type,
      card_title: _title,
      message_text: _message_text,
      card_content: JSON.stringify(resultNameCardContent),
      image_urls: getImgUrls(resultNameCardContent),
      user_category_id: user_category_id,
      category_name: category_name,
      user_folder_id: user_folder_id,
      folder_name: folder_name,
    };
    try {
      if (_accessToken) {
        const { data: res } = await addNameCards(_accessToken, nameCardContent);
        if (res) {
          const { data } = res;
          const newNameCardContent: INameCardType = JSON.parse(
            JSON.stringify(nameCardContent)
          );
          const hasShareNameCardButton =
            nameCardContent.card_content.indexOf("shareNameCardButton") !== -1;
          const nameCardHash = data.data.hash_id;
          const cardID = data.data.id;
          if (hasShareNameCardButton) {
            const { card_content } = newNameCardContent;
            const newCardContent: CFlexBubble[] = JSON.parse(card_content);
            const result: CFlexBubble[] = newCardContent.map(
              (_flexMsg, index) => {
                return {
                  ..._flexMsg,
                  body: {
                    ..._flexMsg.body,
                    contents: _flexMsg.body?.contents.map((item, index) => {
                      if (
                        item.type === "box" &&
                        item.action?.label === "分享名片" &&
                        item.action.type === "uri" &&
                        item.contents &&
                        item.contents.length > 0 &&
                        item.contents[0].type === "text" &&
                        item.contents[0].text === "shareNameCardButton"
                      ) {
                        return {
                          ...item,
                          action: {
                            ...item.action,
                            uri: item.action.uri + nameCardHash,
                          },
                        };
                      }
                      return item;
                    }),
                  },
                } as CFlexBubble;
              }
            );

            const updateNameCardData: INameCardType = {
              card_type: result.length > 1 ? 2 : 1,
              card_title: _title,
              message_text: _message_text,
              card_content: JSON.stringify(result),
              image_urls: getImgUrls(result),
              user_category_id: user_category_id,
              category_name: category_name,
              user_folder_id: user_folder_id,
              folder_name: folder_name,
            };
            const { data: updateNameCardRes } = await updateNameCards(
              _accessToken,
              cardID,
              updateNameCardData
            );
            console.log(updateNameCardRes);
          }
        }
      }
    } catch (error) {
      console.log("建立新名片錯誤", error);
    }
  };

  const handleSubmit = async () => {
    if (!nameCardV3Form) return;
    try {
      const values = await nameCardV3Form.validateFields();
      if (!hasImageInThisNameCard(nameCardV3Data)) {
        message.error("尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。");
        return;
      }
      // 驗證通過才執行提交邏輯
      console.log("所有表單驗證通過，表單數據：", values);
      accessToken &&
        AddNameCard(
          accessToken,
          nameCardV3Data,
          cardTitle,
          altText,
          nameCardV3Data.length === 1 ? 1 : 2
        );
      navigate(`/`);
      // 執行提交邏輯
    } catch (errorInfo) {
      // 驗證失敗，errorInfo 包含錯誤信息
      console.log("表單驗證失敗:", errorInfo);
      message.error("請依照欄位提示修正以符合所需格式");
      if (!hasImageInThisNameCard(nameCardV3Data)) {
        message.error("尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。");
        return;
      }
    }
  };

  const handleSend = async () => {
    try {
      if (!nameCardV3Form) return;
      const values = await nameCardV3Form.validateFields();
      if (!hasImageInThisNameCard(nameCardV3Data)) {
        message.error("尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。");
        return;
      }
      console.log("所有表單驗證通過，表單數據：", values);
      sendFlexMsgFromCurrentContent(
        sendMsgContent._flexMsgContent,
        sendMsgContent._message_text
      );
    } catch (error) {
      console.log("發送名片錯誤", error);
      message.error("發送名片錯誤,請依照欄位提示修正以符合所需格式");
      if (!hasImageInThisNameCard(nameCardV3Data)) {
        message.error("尚未上傳底圖圖片，請至少上傳一張圖片在電子名片中。");
        return;
      }
    }
  };

  const handlePrevPage = () => {
    if (nameCardV3CurrentPage > 0) {
      setNameCardV3CurrentPage(nameCardV3CurrentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (nameCardV3CurrentPage < nameCardV3Data.length - 1) {
      setNameCardV3CurrentPage(nameCardV3CurrentPage + 1);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.nameCardEditorNavBar}`}>
      {/* 新增頁面切換按鈕 */}
      <div className={classes.pageNavigation}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.pageButton}`}
          onClick={handlePrevPage}
          disabled={nameCardV3CurrentPage === 0}
        >
          <div className={classes.buttonWord}>上一頁</div>
        </Button>
        <span className={classes.pageInfo}>
          {nameCardV3CurrentPage + 1} / {nameCardV3Data.length}
        </span>
        <Button
          type="primary"
          className={`${classes.button} ${classes.pageButton}`}
          onClick={handleNextPage}
          disabled={nameCardV3CurrentPage === nameCardV3Data.length - 1}
        >
          <div className={classes.buttonWord}>下一頁</div>
        </Button>
      </div>
      <div className={classes.buttonContainer}>
        <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
          <Button
            type="primary"
            className={`${classes.button} ${classes.buttonGreyBg}`}
            onClick={() => navigate(`/`)}
          >
            <div className={classes.buttonWord}>取消</div>
          </Button>
        </div>
        <div className={classes.buttonItem}>
          <Button
            type="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            <div className={classes.buttonWord}>儲存</div>
          </Button>
        </div>
        <div className={classes.buttonItem + " " + classes.sendButton}>
          <Button type="primary" className={classes.send} onClick={handleSend}>
            <div className={classes.buttonWord}>發送</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateNameCardFullImgModeFooter;
