export type configType = {
  [key: string]: {
    REACT_APP_API_URL: string;
    REACT_APP_ENV: string;
    REACT_APP_VIP_URL: string;
    REACT_APP_LIFFID: string;
    REACT_APP_IMAGEPATH: string;
    REACT_APP_IMAGEBASEURL: string;
    REACT_APP_COUPON_IMAGE: string;
  };
};

export const config: configType = {
  dev: {
    REACT_APP_ENV: "dev",
    REACT_APP_API_URL: "https://api-dev.zinbin.net",
    REACT_APP_VIP_URL: "https://vip-dev.zinbin.net",
    REACT_APP_LIFFID: "1657460946-m61dAVOJ", // 24.10.29 SCH, 1657460946-m61dAVOJ(dev), 2006512489-MoLmODg5(ngork)
    REACT_APP_IMAGEPATH: "brand/name_cards/",
    REACT_APP_IMAGEBASEURL:
      "https://ipflow-s3-dev.s3.ap-northeast-1.amazonaws.com/",
    REACT_APP_COUPON_IMAGE: "3PnBCfa69yWgv7P68slHCdzv27bbTSCyvXThf06a.jpg",
  },
  beta: {
    REACT_APP_ENV: "beta",
    REACT_APP_API_URL: "https://api-beta.zinbin.net",
    REACT_APP_VIP_URL: "https://vip-beta.zinbin.net",
    REACT_APP_LIFFID: "1657460947-JeBKV9O1",
    REACT_APP_IMAGEPATH: "brand/name_cards/",
    REACT_APP_IMAGEBASEURL:
      "https://ipflow-s3-beta.s3.ap-northeast-1.amazonaws.com/",
    REACT_APP_COUPON_IMAGE: "Bl7UsckHd3heEUXkPMuuHWsatBqvO3Ss2FOlvSUU.jpg",
  },
  prod: {
    REACT_APP_ENV: "prod",
    REACT_APP_API_URL: "https://api.zinbin.net",
    REACT_APP_VIP_URL: "https://vip.zinbin.net",
    REACT_APP_LIFFID: "1657460954-qbmy6dAr",
    REACT_APP_IMAGEPATH: "brand/name_cards/",
    REACT_APP_IMAGEBASEURL:
      "https://ipflow-s3.s3.ap-northeast-1.amazonaws.com/",
    REACT_APP_COUPON_IMAGE: "ogis5ndCFMLHC1h45xX4QYzw0x65kLZvA9qxIrnF.jpg",
  },
};
