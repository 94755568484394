import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import classes from "./UpdateNameCard.module.sass";
import { queryThisNameCard } from "../../api";
import { nameCardType } from "../../api";
import CreateNameCard from "../CreateNameCard";

// import { useNameCardFullImgMode } from '../../context/NameCardFullImgMode';
import CreateFullImgModeNameCard from "../CreateFullImgModeNameCard";
import Loading from "../Loading";

import { useAppStateStore } from "../../store/appState";

const UpdateNameCard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [nameCardData, setNameCardData] = useState<nameCardType>();

  // const { setNameCardFullImgModeContent } = useNameCardFullImgMode();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [cardHashID, setCardHashID] = useState<string | undefined>(undefined);

  const { PAGEMODE, NAMECARD_OPERATION, USER, APP } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } =
    NAMECARD_OPERATION;
  const { currentNameCardType } = nameCardOperationState;
  const { accessToken } = USER.userState;
  const { currentConfig } = APP;
  const oldS3BaseUrl = "https://ipflow-dev-s3.s3.ap-northeast-1.amazonaws.com/";

  // console.log(params.id);

  const QueryThisNameCard = async (_card_id: string) => {
    try {
      if (accessToken) {
        const { data: res } = await queryThisNameCard(accessToken, _card_id);
        if (res) {
          const { data } = res;
          setCardHashID(data.hash_id);
          // console.log(data.card_content);
          // 如果有 contnet 的文字就把它改成content
          // 判斷有 flexMessageSetting 是舊的 key
          // 新的 key 名稱是 nameCardV1Setting
          if (
            data.card_content.indexOf("contnet") !== -1 &&
            data.card_content.indexOf("flexMessageSetting") !== -1
          ) {
            data.card_content = data.card_content.replace(
              /contnet/g,
              "content"
            );
            data.card_content = data.card_content.replace(
              /flexMessageSetting/g,
              "nameCardV1Setting"
            );
            data.card_content = data.card_content.replace(
              /flexMessage/g,
              "nameCardV1Data"
            );
          }
          // console.log(data.card_content);

          if (data.card_content.indexOf("carousel") !== -1) {
            setNameCardOperationState({ currentNameCardType: "v3.0" });
            if (
              data.card_content.indexOf(currentConfig.REACT_APP_IMAGEPATH) !==
              -1
            ) {
              const willReplace = new RegExp(
                `${currentConfig.REACT_APP_IMAGEPATH}`,
                "g"
              );
              data.card_content = data.card_content.replace(
                willReplace,
                currentConfig.REACT_APP_IMAGEBASEURL +
                  currentConfig.REACT_APP_IMAGEPATH
              );
            }
            setCurrentPageModeState("updateNameCardFullImgMode");
          } else if (data.card_content.indexOf("bubble") !== -1) {
            setNameCardOperationState({ currentNameCardType: "v3.0" });
            if (
              !data.card_content.includes(oldS3BaseUrl) &&
              data.card_content.indexOf(currentConfig.REACT_APP_IMAGEPATH) !==
                -1
            ) {
              const willReplace = new RegExp(
                `${currentConfig.REACT_APP_IMAGEPATH}`,
                "g"
              );
              data.card_content = data.card_content.replace(
                willReplace,
                currentConfig.REACT_APP_IMAGEBASEURL +
                  currentConfig.REACT_APP_IMAGEPATH
              );
            }
            // console.log(data.card_content);
            setCurrentPageModeState("updateNameCardFullImgMode");
          } else if (data.card_content.indexOf("nameCardV1Data") !== -1) {
            setNameCardOperationState({ currentNameCardType: "v1.0" });

            try {
              setCurrentPageModeState("updateNameCard");
              // console.log(data.card_content);
              setNameCardData(data);
            } catch (error) {
              console.log("Json轉換錯誤", error);
            }
          }
          setIsReady(true);
        }
      }
    } catch (error) {
      console.log("error", error);
      console.log("flex message 格式不符合 跳轉回首頁");
      navigate(`/`);
    }
  };

  useEffect(() => {
    if (params.id) {
      QueryThisNameCard(params.id);
      setNameCardOperationState({ action: "update" });
    }
  }, [accessToken]);

  return (
    <div className={classes.container}>
      {!isReady ? (
        <Loading />
      ) : (
        <div className={classes.content}>
          {currentNameCardType === "v1.0" && (
            <CreateNameCard
              isUpdate={true}
              updateData={nameCardData}
              cardId={Number(params.id)}
              cardHashID={cardHashID}
            />
          )}
          {currentNameCardType === "v3.0" && (
            <CreateFullImgModeNameCard
              isUpdate={true}
              cardid={Number(params.id)}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default UpdateNameCard;
