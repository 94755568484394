/**
 * 檢查所有按鈕是否通過驗證
 */
export const isAllValidationsPassed = (buttonValidate: boolean[]) => {
  return buttonValidate.every((result) => result === true);
};

/**
 * 檢查電話是否是有效電話
 */
export const validatePhoneNumber = (value: string) => {
  if (value && value.length === 10 && value[0] === "0" && value[1] === "9") {
    return true;
  } else {
    return false;
  }
};
