import { Tabs, Col, Row, Modal, message, Tooltip } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { ICreateNameCardProps } from "./Type";
import NameCard from "./NameCard";
import useKeyword2Px from "../../hooks/useKeyword2Px";
import classes from "./CreateNameCard.module.sass";
import {
  buttonType,
  initNameCardV1Data,
  nameCardV1DataType,
  nameCardV1SettingType,
  useAppStateStore,
} from "../../store/appState";
import CategorySelector from "./CategorySelector";

interface IItem {
  label: string;
  children: JSX.Element;
  key: string;
  closable: boolean;
}

const initialItems = [
  {
    label: "1",
    children: <NameCard id="newTab-1" />,
    key: "newTab-1",
    closable: false,
  },
];

const CreateNameCard = ({
  isUpdate,
  updateData,
  cardHashID,
}: ICreateNameCardProps) => {
  const { BRAND, USER, PAGEMODE, NAMECARD_DATA, APP } = useAppStateStore();
  const { currentConfig } = APP;
  const { brandState } = BRAND; // 25.01.28 SCH ★
  const { userState } = USER; // 25.01.28 SCH ★
  const { liff_id_promo } = brandState; // 25.03.04 SCH ★
  const { setCurrentPageModeState } = PAGEMODE;
  const {
    nameCardData,
    setNameCardV1Data,
    setNameCardV1Setting,
    setCurrentCategory,
    setNameCardV1CurrentActiveKey,
  } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting, nameCardV1CurrentActiveKey } =
    nameCardData;

  const [items, setItems] = useState(initialItems);
  const newTabIndex = useRef(1);
  const [isPermissionMsgModalOpen, setIsPermissionMsgModalOpen] =
    useState<boolean>(false);
  const nameCardPageLimit = useRef(1); // 25.01.27 SCH ★
  console.log("isUpdate", isUpdate);
  const previewBlockRef = useRef<HTMLDivElement>(null);

  const { fontSize2Px } = useKeyword2Px();

  useEffect(() => {
    if (userState.role >= 2 || userState.eagle) {
      nameCardPageLimit.current = brandState.name_card_page_2; // 25.01.27 SCH ★
    } else {
      nameCardPageLimit.current = brandState.name_card_page_1; // 25.01.27 SCH ★
    }
  }, []);

  useEffect(() => {
    setCurrentPageModeState(isUpdate ? "updateNameCard" : "createNameCard");
    // 25.03.04 SCH ★ set default value for buttonUrl & Text
    const newPanes = [...items]; // 25.03.04 SCH ★
    if (nameCardV1Data && !isUpdate && newPanes.length === 1) {
      AlterNameCardV1Data();
    }
  }, []);

  useEffect(() => {
    if (updateData && isUpdate) {
      try {
        const content: {
          nameCardV1Setting: nameCardV1SettingType;
          nameCardV1Data: nameCardV1DataType[];
        } = JSON.parse(updateData.card_content);
        setNameCardV1Data(content.nameCardV1Data);
        setNameCardV1Setting(content.nameCardV1Setting);
        setCurrentCategory({
          user_category_id: updateData.user_category_id,
          category_name: updateData.category_name,
          user_folder_id: updateData.user_folder_id,
          folder_name: updateData.folder_name,
        });

        let tempItems: IItem[] = [];

        content.nameCardV1Data.forEach((flexMsg, index) => {
          const tempItem: IItem = {
            label: `${index + 1}`,
            children: (
              <NameCard id={`newTab-${index + 1}`} cardHashID={cardHashID} />
            ),
            key: `newTab-${index + 1}`,
            closable: index !== 0,
          };

          tempItems.push(tempItem);
        });
        setItems(tempItems);
        setNameCardV1CurrentActiveKey(
          `newTab-${content.nameCardV1Data.length}`
        );
        newTabIndex.current = content.nameCardV1Data.length;
      } catch (error) {}
    }
  }, [updateData]);

  const onChange = (newActiveKey: string) => {
    setNameCardV1CurrentActiveKey(newActiveKey);
  };

  const add = () => {
    const newPanes = [...items];
    console.log("nameCardPageLimit =", nameCardPageLimit.current);
    console.log("[role, eagle]", userState.role, userState.eagle); // 25.01.28 SCH ★
    if (newPanes.length >= nameCardPageLimit.current) {
      // if (is_normal && role !== 2) return setIsPermissionMsgModalOpen(true);
      if (userState.role < 2 && !userState.eagle)
        return setIsPermissionMsgModalOpen(true); // 25.01.28 SCH ★
      return message.warning("已經滿(" + nameCardPageLimit.current + ")頁");
    }

    newTabIndex.current = newTabIndex.current + 1;
    console.log("newTabIndex.current", newTabIndex.current);
    const newActiveKey = `newTab-${newTabIndex.current}`;

    // 新增新的名片資料
    const tempData: nameCardV1DataType = JSON.parse(
      JSON.stringify(initNameCardV1Data)
    );
    tempData.id = newActiveKey;
    tempData.imageUrl[0]._key = newActiveKey;
    tempData.IntroText[0]._key = newActiveKey;
    tempData.button[0].content._key = newActiveKey;
    // 25.03.04 SCH ★ set default value for buttonUrl & Text
    tempData.button[0].content.buttonText = "進入電子名片︰編輯器";
    tempData.button[0].content.buttonUrl =
      liff_id_promo ?? "https://liff.line.me/1657460954-qbmy6dAr";

    // 更新 nameCardV1Data
    const tempFlexMsg: nameCardV1DataType[] = JSON.parse(
      JSON.stringify(nameCardV1Data)
    );
    tempFlexMsg.push(tempData);
    const resultFlexMsg: nameCardV1DataType[] = tempFlexMsg.map(
      (item, index) => ({
        ...item,
        id: `newTab-${index + 1}`,
      })
    );

    // 更新 items 新增新的頁籤
    newPanes.push({
      label: `${newTabIndex.current}`,
      children: <NameCard id={newActiveKey} />,
      key: newActiveKey,
      closable: true,
    });

    setNameCardV1Data(resultFlexMsg);
    setItems(newPanes);
    setNameCardV1CurrentActiveKey(newActiveKey);
  };

  // 25.03.04 SCH ★ set default value for buttonUrl & Text
  const AlterNameCardV1Data = () => {
    // 變更 nameCardV1Data
    const tempFlexMsg: nameCardV1DataType[] = JSON.parse(
      JSON.stringify(nameCardV1Data)
    );
    console.log("AlterInitNameCardV1Data tempFlexMsg", tempFlexMsg);
    // 25.03.04 SCH ★ set default value for buttonUrl & Text
    tempFlexMsg[0].button[0].content.buttonText = "進入電子名片︰編輯器";
    tempFlexMsg[0].button[0].content.buttonUrl =
      liff_id_promo ?? "https://liff.line.me/1657460954-qbmy6dAr";
    console.log("AlterInitNameCardV1Data tempFlexMsg[0]", tempFlexMsg[0]);
    setNameCardV1Data(tempFlexMsg);
  };

  const remove = (targetKey: string) => {
    let newActiveKey = JSON.stringify(nameCardV1CurrentActiveKey);
    let lastIndex = -1;
    let resultFelxMsg: nameCardV1DataType[] = [];
    newTabIndex.current = newTabIndex.current - 1;

    (JSON.parse(JSON.stringify(items)) as IItem[]).forEach((item, index) => {
      if (item.key === targetKey) {
        const newNameCardData = (
          JSON.parse(JSON.stringify(nameCardV1Data)) as nameCardV1DataType[]
        ).filter((newFlex) => newFlex.id !== targetKey);

        resultFelxMsg = newNameCardData.map((_flexMsg, _flexMsgindex) => {
          const newButton: buttonType[] = _flexMsg.button.map((_button) => {
            const commonProps = {
              _key: `newTab-${_flexMsgindex + 1}`,
              id: _button.id,
            };

            switch (_button.type) {
              case "urlButton":
                return {
                  ...commonProps,
                  type: "urlButton",
                  content: {
                    ..._button.content,
                    _key: `newTab-${_flexMsgindex + 1}`,
                  },
                };
              case "phoneButton":
                return {
                  ...commonProps,
                  type: "phoneButton",
                  content: {
                    ..._button.content,
                    _key: `newTab-${_flexMsgindex + 1}`,
                  },
                };
              case "shareNameCardButton":
                return {
                  ...commonProps,
                  type: "shareNameCardButton",
                  content: {
                    ..._button.content,
                    _key: `newTab-${_flexMsgindex + 1}`,
                  },
                };
              default:
                // Handle default case or throw an error if needed
                throw new Error(
                  `Unknown button type: ${(_button as buttonType).type}`
                );
            }
          });

          return {
            ..._flexMsg,
            id: `newTab-${_flexMsgindex + 1}`,
            imageUrl: [
              { ..._flexMsg.imageUrl[0], _key: `newTab-${_flexMsgindex + 1}` },
            ],
            IntroText: _flexMsg.IntroText.map((_introText) => ({
              ..._introText,
              _key: `newTab-${_flexMsgindex + 1}`,
            })),
            button: newButton,
          };
        });

        lastIndex = index - 1;
      }
    });

    const newPanes = items
      .filter((item) => item.key !== targetKey)
      .map((item, index) => ({
        ...item,
        key: `newTab-${index + 1}`,
        label: `${index + 1}`,
        closable: index !== 0,
        children: <NameCard id={`newTab-${index + 1}`} />,
      }));

    // 設定 nameCardV1CurrentActiveKey
    if (lastIndex >= 0) {
      newActiveKey = newPanes[lastIndex].key;
    } else {
      newActiveKey = newPanes[0].key;
    }

    const temp = resultFelxMsg.map((item, i) => ({
      ...item,
      id: `newTab-${i + 1}`,
    }));

    setNameCardV1Data(temp);

    setItems(newPanes);
    setNameCardV1CurrentActiveKey(newActiveKey);
  };

  useEffect(() => {
    console.log("currentFlexMsg", nameCardV1Data);
  }, [nameCardV1Data]);

  const onEdit = (targetKey: string, action: "add" | "remove") => {
    if (action === "add") {
      console.log("function - action = onEdit() -", action); // 25.01.28 SCH ★
      add();
    } else {
      remove(targetKey);
    }
  };

  useEffect(() => {
    // console.log('REACT_APP_ENV', process.env.REACT_APP_ENV);
    console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    console.log("VERCEL_ENV", process.env.VERCEL_ENV);
  }, []);

  const handleOk = () => {
    setIsPermissionMsgModalOpen(false);
  };

  const handleCancel = () => {
    setIsPermissionMsgModalOpen(false);
  };

  useEffect(() => {
    if (!previewBlockRef.current) return;

    const currentIndex = Number(nameCardV1CurrentActiveKey.split("-")[1]) - 1;
    const scrollPosition = currentIndex * (300 + 32);

    previewBlockRef.current.scrollTo({
      left: scrollPosition,
      top: 0,
      behavior: "smooth",
    });
  }, [nameCardV1CurrentActiveKey]);

  // 25.03.04 SCH ★ (for test by RichardSu)
  const OverflowText = (valveOfButtonUrl: string) => {
    return (
      <Tooltip title={valveOfButtonUrl}>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "200px", // Adjust the width as needed
          }}
        >
          {valveOfButtonUrl}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <Row>
        <Col className={classes.container}>
          {/* 新增類別和資料夾選擇區塊 */}
          {userState.role >= 2 || userState.eagle ? <CategorySelector /> : null}
          <Row justify="center" align="middle" wrap={true} gutter={[16, 16]}>
            <Col lg={8} md={24} xs={24} sm={24} className={classes.tab}>
              <Tabs
                tabPosition="top"
                animated={true}
                type="editable-card"
                size="large"
                onChange={onChange}
                activeKey={nameCardV1CurrentActiveKey}
                onEdit={(e, action) => onEdit(e.toString(), action)}
                items={items}
              />
            </Col>
            <Col
              lg={16}
              md={24}
              sm={24}
              xs={24}
              className={classes.previewBlock}
              ref={previewBlockRef}
            >
              <div className={classes.preview}>
                <div style={{ display: "flex" }}>
                  {nameCardV1Data.map((bubble, index) => (
                    <div
                      id={bubble.id}
                      key={`bubble-${bubble.id}`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "10px 10px",
                        width: 300,
                        height: "auto",
                        margin: "1rem",
                        background: `${bubble.cardBackgroundColor}`,
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <img
                          style={{
                            borderRadius: "10px 10px 0px 0px",
                            width: "100%",
                            height: "170px",
                            objectFit: "cover",
                          }}
                          src={
                            bubble.imageUrl[0].imageUrl.includes(
                              currentConfig.REACT_APP_IMAGEPATH
                            )
                              ? currentConfig.REACT_APP_IMAGEBASEURL +
                                bubble.imageUrl[0].imageUrl
                              : bubble.imageUrl[0].imageUrl
                          }
                          alt=""
                        />
                      </div>
                      <div
                        // key={`bubble-${bubble.id}-content`}
                        style={{
                          padding: "0px 10px",
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <div
                          key={`bubble-${bubble.id}-content-title`}
                          style={{
                            flex: 1,
                            height: "100%",
                            marginBottom: "20px",
                            padding: "0px 10px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: `${
                                nameCardV1Setting.titleFontSize &&
                                fontSize2Px(nameCardV1Setting.titleFontSize)
                              }`,
                              color: `${bubble.titleColor}`,
                            }}
                          >
                            {bubble.title}
                          </div>
                          <div
                            style={{
                              color: bubble.introTextColor,
                              fontSize:
                                nameCardV1Setting.describeFontSize &&
                                fontSize2Px(nameCardV1Setting.describeFontSize),
                            }}
                          >
                            {bubble.IntroText.map((_introText) => (
                              <div key={_introText.id}>
                                {_introText.inputValue}
                              </div>
                            ))}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: ".5rem",
                            width: "280px",
                            flex: 1,
                          }}
                        >
                          {bubble.button.map((_button) => (
                            <div
                              key={_button.content?._key}
                              style={{
                                background: "#496b87",
                                borderRadius: "6px",
                                minWidth:
                                  bubble.button.length > 3 ? "135px" : "100%",
                                flex: 1,
                                marginBottom: "5px",
                                height: "52px",
                                color: "#fff",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "0 16px",
                                }}
                              >
                                <span
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {_button.type === "phoneButton"
                                    ? "電話聯繫"
                                    : _button.type === "shareNameCardButton"
                                      ? "分享名片"
                                      : _button.type === "urlButton" &&
                                        _button.content?.buttonText}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title="會籍權限通知"
        open={isPermissionMsgModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        付費升級為正式會員才可使用多頁名片功能！
      </Modal>
    </>
  );
};
export default CreateNameCard;
