/**
 * 會員個人基本資料是否齊全
 * @param mobileNumberInputValue 手機號碼 input 的值
 * @param realnameInputValue 會員姓名 input 的值
 * @param emailInputValue 會員-email input 的值
 * @param mobileNumber userState.mobile_number
 * @param realname userState.realname
 * @param email userState.email // 25.02.07 SCH ★
 */
const isMemberProfileFulfilled = (
  mobileNumberInputValue: string | undefined | null,
  realnameInputValue: string | undefined | null,
  emailInputValue: string | undefined | null,
  mobileNumber: string | undefined | null,
  realname: string | undefined | null,
  email: string | undefined | null
) => {
  let profileMobileNumberTrim = mobileNumberInputValue?.trim();
  let profileRealnameTrim = realnameInputValue?.trim();
  let profileEmailTrim = emailInputValue?.trim();
  let mobileNumberTrim = mobileNumber?.trim();
  let realnameTrim = realname?.trim();
  let emailTrim = email?.trim();

  var truefalse = true; // 25.02.07 SCH ★

  if (
    (profileMobileNumberTrim?.length === 0 ||
      mobileNumberInputValue === undefined ||
      mobileNumberInputValue === null) &&
    (mobileNumberTrim?.length === 0 ||
      mobileNumber === undefined ||
      mobileNumber === null)
  )
    truefalse = false; // 25.02.07 SCH ★

  if (
    (profileRealnameTrim?.length === 0 ||
      realnameInputValue === undefined ||
      realnameInputValue === null) &&
    (realnameTrim?.length === 0 || realname === undefined || realname === null)
  )
    truefalse = false; // 25.02.07 SCH ★

  if (
    (profileEmailTrim?.length === 0 ||
      emailInputValue === undefined ||
      emailInputValue === null) &&
    (emailTrim?.length === 0 || email === undefined || email === null)
  )
    truefalse = false; // 25.02.07 SCH ★

  return truefalse;
};

export default isMemberProfileFulfilled;
