// import { FolderOutlined, PlusOutlined } from "@ant-design/icons";
import { Divider, Form, Select } from "antd";
import classes from "./CategorySelector.module.sass";
import { useAppStateStore } from "../../../store/appState";
import {
  queryUserCategories,
  UserCategoriesDataType,
} from "../../../api/category";
import {
  getUserFolderByCategoryId,
  UserFolderDataType,
} from "../../../api/folder";
import { useEffect, useState } from "react";

const CategorySelector = () => {
  const { Option } = Select;
  const { USER, NAMECARD_DATA } = useAppStateStore();
  const { accessToken } = USER.userState;
  const { setCurrentCategory, nameCardData } = NAMECARD_DATA;
  const { currentCategory } = nameCardData;
  const { user_category_id, user_folder_id, category_name, folder_name } =
    currentCategory;
  const [form] = Form.useForm();

  const [categoryList, setCategoryList] = useState<UserCategoriesDataType[]>(
    []
  );
  const [folderList, setFolderList] = useState<UserFolderDataType[]>([]);

  /**
   * 查詢名片類別
   */
  const queryCategoryList = async (withOfficial: string) => {
    try {
      if (accessToken) {
        const { data: res } = await queryUserCategories(
          accessToken,
          withOfficial
        );
        // 25.02.26 SCH ★ withOfficial = 2 = not with_official
        console.log(
          "CreateNameCard > CategorySelector > index.tsx ",
          "> queryCategoryList > await queryUserCategories",
          "withOfficial = 2"
        );
        res?.data && console.log("queryUserCategories", res.data);
        res?.data && setCategoryList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 查詢資料夾 by categoryId 名片類別ID
   * @param categoryId 名片類別ID
   */
  const queryFolderList = async (categoryId: number) => {
    try {
      if (accessToken) {
        const { data: res } = await getUserFolderByCategoryId(
          accessToken,
          categoryId
        );
        res?.data && setFolderList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 處理名片類別選擇事件
   * @param value 選擇的名片類別ID
   */
  const handleCategoryChange = (value: string | undefined) => {
    const categoryName =
      categoryList.find(
        (category) => category.user_category_id === Number(value)
      )?.name || null;

    setCurrentCategory({
      ...currentCategory,
      user_category_id: Number(value) || null,
      category_name: categoryName,
      user_folder_id: null,
      folder_name: null,
    });

    form.setFieldValue("folder", null);
  };

  /**
   * 處理資料夾選擇事件
   * @param value 選擇的資料夾ID
   */
  const handleFolderChange = (value: string | undefined) => {
    const folderName =
      folderList.find((folder) => folder.user_folder_id === Number(value))
        ?.name || null;

    setCurrentCategory({
      ...currentCategory,
      user_folder_id: Number(value) || null,
      folder_name: folderName,
    });
  };

  useEffect(() => {
    queryCategoryList("2"); // 25.02.26 SCH ★
  }, [accessToken]);

  useEffect(() => {
    user_category_id && queryFolderList(user_category_id);
  }, [user_category_id]);

  useEffect(() => {
    form && category_name && form.setFieldValue("category", category_name);
    form && folder_name && form.setFieldValue("folder", folder_name);
  }, [category_name, folder_name, form]);

  return (
    <Form className={classes.container} form={form} layout="horizontal">
      <Form.Item label="名片類別" name="category" className={classes.select}>
        <Select
          placeholder="選擇名片類別"
          allowClear={true}
          value={user_category_id ? user_category_id.toString() : undefined} // 修改這裡
          onChange={handleCategoryChange}
          dropdownRender={(categoryList) => (
            <>
              {categoryList}
              <Divider className={classes.divider} />
              {/* <Button
                  type="text"
                  icon={<PlusOutlined rev={""} />}
                  className={classes.space}
                >
                  新增類別
                </Button> */}
            </>
          )}
        >
          {categoryList.map((category) => (
            <Option
              key={`category-${category.name}`}
              value={category.user_category_id.toString()}
            >
              {category.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="資料夾" name="folder" className={classes.select}>
        <Select
          placeholder="選擇資料夾"
          allowClear={true}
          value={user_folder_id?.toString() || null}
          onChange={handleFolderChange}
          dropdownRender={(folderList) => (
            <>
              {folderList}
              <Divider className={classes.divider} />
              {/* <Button
                  type="text"
                  icon={<FolderOutlined rev={""} />}
                  className={classes.space}
                >
                  新增資料夾
                </Button> */}
            </>
          )}
        >
          {folderList.map((folder) => (
            <Option
              key={`folder-${folder.user_folder_id}`}
              value={folder.user_folder_id.toString()}
            >
              {folder.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default CategorySelector;
