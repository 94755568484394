import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";

import classes from "../Footer.module.sass";
import { INameCardType } from "../../../interface/nameCard";

import { updateNameCards } from "../../../api";
import {
  IFlexMsgContent,
  useShareFlexMsg,
} from "../../../hooks/useShareFlexMsg";
import { nameCardV1DataType, useAppStateStore } from "../../../store/appState";

const UpdateNameCardFooter = () => {
  const { USER, NAMECARD_DATA } = useAppStateStore();
  const { accessToken } = USER.userState;
  const { nameCardData, initNameCardV1Form, setNameCardV1CurrentActiveKey } =
    NAMECARD_DATA;
  const {
    nameCardV1Data,
    nameCardV1Setting,
    currentCategory,
    nameCardV1Form,
    nameCardV1CurrentActiveKey,
  } = nameCardData;

  const navigate = useNavigate();
  const [updateCardID, setUpdateCardID] = useState<number>(0);
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();

  const sendMsgContent: {
    _flexMsgContent: IFlexMsgContent;
    _messageText: string;
  } = {
    _flexMsgContent: {
      flexMsgType: { flexMsgType: "V1.0FlexMsg" },
      currentContent: {
        flexMsg: nameCardV1Data,
        flexMsgSetting: nameCardV1Setting,
      },
    },
    _messageText: nameCardV1Setting.altText,
  };

  useEffect(() => {
    const tempHref = window.location.href;
    const tempHrefArr = tempHref.split("update/");
    const _card_id = Number(tempHrefArr[tempHrefArr.length - 1]);
    setUpdateCardID(_card_id);
  }, []);

  const getImgUrls = (content: nameCardV1DataType[] | null) => {
    if (!content) return;
    const imageUrls: string[] = [];
    content.forEach((message) => {
      message.imageUrl.forEach((image) => {
        if (image.imageUrl.includes("brand/name_cards/")) {
          imageUrls.push(image.imageUrl);
        }
      });
    });
    return imageUrls;
  };

  /**
   * 發更新資料庫該cardId名片資料 api
   */
  const UpdateNameCards = async () => {
    const _cardContent = {
      nameCardV1Setting: nameCardV1Setting,
      nameCardV1Data: nameCardV1Data,
    };

    const nameCardContent: INameCardType = {
      card_type: nameCardV1Data && nameCardV1Data.length === 1 ? 1 : 2,
      card_title: nameCardV1Setting.cardTitle!,
      message_text: nameCardV1Setting.altText ?? "電子名片訊息",
      card_content: JSON.stringify(_cardContent),
      image_urls: getImgUrls(nameCardV1Data)!,
      user_category_id: currentCategory.user_category_id,
      category_name: currentCategory.category_name,
      user_folder_id: currentCategory.user_folder_id,
      folder_name: currentCategory.folder_name,
    };

    try {
      if (accessToken && updateCardID !== undefined) {
        const { data: res, error } = await updateNameCards(
          accessToken,
          updateCardID,
          nameCardContent
        );
        if (res) {
          const { data } = res;
          console.log("修改名片成功", data);
        }
        // 25.02.24 SCH ★★★
        if (error) {
          const errorData = JSON.stringify(error?.response?.data);
          if (
            errorData.includes(
              "You are not allowed to alter the category or folder"
            )
          ) {
            message.warn("您沒有權限修改名片的類別或資料夾", 7);
          } else if (
            errorData.includes(
              "The category or folder is not allowed to be changed"
            )
          ) {
            message.warn("「官網朋友圈」的名片類別或資料夾不能被修改", 7);
          } else if (errorData.includes("not permitted to alter data")) {
            message.warn("您沒有修改該張名片的權限", 7);
          } else if (errorData.includes("not permitted to edit")) {
            message.warn("您沒有修改名片的權限", 7);
          } else if (errorData.includes("You must be a paid member firstly")) {
            message.warn(
              "非付費會員不得編輯名片類別或資料夾，可在個人資料頁面下方點按〈付費解鎖進階功能〉，感謝您！",
              7
            );
          } else {
            message.error("修改名片失敗");
          }
        }
      }
    } catch (error) {
      console.log("修改名片失敗", error);
    }
  };

  /**
   * 表單驗證
   */
  const handleSubmit = async () => {
    try {
      const forms = Object.values(nameCardV1Form);
      const values = await Promise.all(
        forms.map((item) => item.validateFields())
      );
      // 驗證通過才執行提交邏輯
      console.log("所有表單驗證通過，表單數據：", values);
      accessToken && UpdateNameCards();
      navigate(`/`);
      // 重置所有表單
      initNameCardV1Form();

      // 執行提交邏輯
    } catch (errorInfo) {
      // 驗證失敗，errorInfo 包含錯誤信息
      console.log("表單驗證失敗:", errorInfo);
      message.error("請依照欄位提示修正以符合所需格式");
    }
  };

  /**
   * 取消
   */
  const handleCancel = () => {
    navigate(`/`);
  };

  /**
   * 發送名片
   */
  const handleSendNameCard = async () => {
    try {
      const forms = Object.values(nameCardV1Form);
      const values = await Promise.all(
        forms.map((item) => item.validateFields())
      );
      // 驗證通過才執行提交邏輯
      console.log("所有表單驗證通過，表單數據：", values);
      sendFlexMsgFromCurrentContent(
        sendMsgContent._flexMsgContent,
        sendMsgContent._messageText
      );
    } catch (error) {
      console.log("發送名片錯誤", error);
      message.error("發送名片錯誤,請檢查表單是否填寫正確");
    }
  };

  const handlePrevPage = () => {
    if (nameCardV1Data.length > 1) {
      const currentIndex = nameCardV1CurrentActiveKey.split("-")[1];
      if (Number(currentIndex) > 1) {
        setNameCardV1CurrentActiveKey(`newTab-${Number(currentIndex) - 1}`);
      }
    }
  };

  const handleNextPage = () => {
    const currentIndex = nameCardV1CurrentActiveKey.split("-")[1];
    if (Number(currentIndex) <= nameCardV1Data.length) {
      setNameCardV1CurrentActiveKey(`newTab-${Number(currentIndex) + 1}`);
    }
  };

  return (
    <div className={`${classes.buttonItem}  ${classes.nameCardEditorNavBar}`}>
      {/* 新增頁面切換按鈕 */}
      <div className={classes.pageNavigation}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.pageButton}`}
          onClick={handlePrevPage}
          disabled={nameCardV1CurrentActiveKey === "newTab-1"}
        >
          <div className={classes.buttonWord}>上一頁</div>
        </Button>
        <span className={classes.pageInfo}>
          {nameCardV1CurrentActiveKey.split("-")[1]} / {nameCardV1Data.length}
        </span>
        <Button
          type="primary"
          className={`${classes.button} ${classes.pageButton}`}
          onClick={handleNextPage}
          disabled={
            nameCardV1CurrentActiveKey.split("-")[1] ===
            nameCardV1Data.length.toString()
          }
        >
          <div className={classes.buttonWord}>下一頁</div>
        </Button>
      </div>

      <div className={classes.buttonContainer}>
        <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
          <Button
            type="primary"
            className={`${classes.button} ${classes.buttonGreyBg}`}
            onClick={handleCancel}
          >
            <div className={classes.buttonWord}>取消</div>
          </Button>
        </div>
        <div className={classes.buttonItem}>
          <Button
            type="primary"
            className={classes.button}
            onClick={handleSubmit}
          >
            <div className={classes.buttonWord}>儲存</div>
          </Button>
        </div>
        <div className={`${classes.buttonItem} ${classes.sendButton}`}>
          <Button
            type="primary"
            className={classes.send}
            onClick={handleSendNameCard}
          >
            <div className={classes.buttonWord}>發送</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateNameCardFooter;
