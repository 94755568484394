import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./components/Member/Profile";
import NameCardsList from "./components/Member/NameCardsList";
import CreateNameCard from "./components/CreateNameCard";
import UpdateNameCard from "./components/UpdateNameCard";
import Trees from "./components/Trees";
import SendFlexMessageFromJson from "./components/SendFlexMessageFromJson";
import EagleSendFlexMsg from "./components/EagleSendFlexMsg";
import CreateFullImgModeNameCard from "./components/CreateFullImgModeNameCard";
import PayCallback from "./components/PayCallback";
import PayCancel from "./components/PayCancel";
import MemberCheck from "./components/MemberCheck";
import UserBonusList from "./components/Member/UserBonusList"; // 24.11.02 SCH ★
import FreePlanRedeemSuccessPage from "./components/FreePlanRedeemSuccessPage";
import CustomCategories from "./components/CustomCategories";
import UserCoupon from "./components/UserCoupon";
import NotFound from "./components/ResultPage/NotFound";
import PaymentTerms from "./components/DocPage/PaymentTerms";
import RegisterPartnersAccountPage from "./components/RegisterPartnersAccountPage";
import ApplyPrivateSharePermissionPage from "./components/ApplyPrivateSharePermissionPage";
const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/update/:id" element={<UpdateNameCard />} />
      <Route
        path="/createNameCard"
        element={<CreateNameCard isUpdate={false} />}
      />
      <Route path="/" element={<NameCardsList />} />
      <Route path="/nameCardsList" element={<Navigate to="/" />} />
      <Route path="/tree" element={<Trees />} />
      <Route
        path="/usejsonsendflexmessage"
        element={<SendFlexMessageFromJson />}
      />
      <Route path="/eaglesendflexMsg" element={<EagleSendFlexMsg />} />
      <Route
        path="/createFullImgMode"
        element={<CreateFullImgModeNameCard isUpdate={false} />}
      />
      <Route path="/pay/callback" element={<PayCallback />} />
      <Route path="/pay/cancel" element={<PayCancel />} />
      <Route path="/userCheck" element={<MemberCheck />} />
      <Route path="/userBonus" element={<UserBonusList />} />
      <Route path="/customCategories" element={<CustomCategories />} />
      <Route path="/userCoupon" element={<UserCoupon />} />
      <Route
        path="/registerPartnersAccount"
        element={<RegisterPartnersAccountPage />}
      />
      <Route
        path="/applyPrivateSharePermission"
        element={<ApplyPrivateSharePermissionPage />}
      />
      <Route
        path="/freePlanRedeemSuccessPage"
        element={<FreePlanRedeemSuccessPage />}
      />
      <Route path="doc/paymentTerms" element={<PaymentTerms />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default CustomRoutes;
