import classes from "./CancelEditModal.module.sass";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppStateStore } from "../../../store/appState";

interface ICancelEditModalProps {
  isOpen: boolean;
  closeCancelEditModalHandler: () => void;
  reCreateEventModalHandler: () => void;
  routerPath: string;
}

const CancelEditModal = ({
  isOpen,
  closeCancelEditModalHandler,
  reCreateEventModalHandler,
  routerPath,
}: ICancelEditModalProps) => {
  const { NAMECARD_OPERATION, NAMECARD_DATA } = useAppStateStore();
  const { nameCardOperationState } = NAMECARD_OPERATION;
  const { action } = nameCardOperationState;
  const { initNameCardV1Data, initNameCardV1Setting, initCurrentCategory } =
    NAMECARD_DATA;
  const navigate = useNavigate();

  const handleNameCardAction = () => {
    navigate(`${routerPath}`);
    closeCancelEditModalHandler();
    initNameCardV1Data();
    initNameCardV1Setting();
    initCurrentCategory();
    action === "reCreate" && reCreateEventModalHandler();
  };

  return (
    <Modal
      className={classes.modalStyle}
      title={<div className={classes.modalTitle}>系統提醒</div>}
      open={isOpen}
      onOk={closeCancelEditModalHandler}
      onCancel={closeCancelEditModalHandler}
      footer={
        <div className={classes.footerControl}>
          <div className={classes.footerButtonItem}>
            <Button
              className={`${classes.footerButton} ${classes.buttonBlueBg}`}
              type="primary"
            >
              <div
                className={classes.buttonWord}
                onClick={handleNameCardAction}
              >
                是
              </div>
            </Button>
          </div>
          <div
            className={`${classes.footerButtonItem}  ${classes.cancelButton} `}
          >
            <Button
              className={`${classes.footerButton} ${classes.buttonGreyBg} `}
              type="primary"
              onClick={closeCancelEditModalHandler}
            >
              <div className={classes.buttonWord}>否</div>
            </Button>
          </div>
        </div>
      }
    >
      <div>
        {`您要取消目前編輯作業，${
          action === "reCreate"
            ? "並新增電子名片"
            : action === "goProfile"
              ? "並查看會員資料"
              : action === "goUserBonus"
                ? "並查看推廣佣金"
                : action === "goCustomCategories"
                  ? "並查看自訂分類"
                  : action === "goRegisterPartnersAccount"
                    ? "並前往註冊合作夥伴(設計師)帳號頁面"
                    : "並查看目前全部名片列表"
        }？`}
      </div>
    </Modal>
  );
};

export default CancelEditModal;
