import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "../Footer.module.sass"; // 24.11.06 SCH ★

const RegisterPartnersAccountFooter = () => {
  const navigate = useNavigate();

  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={() => {
            navigate(`/`);
          }}
        >
          <div className={classes.buttonWord}>關閉</div>
        </Button>
      </div>
    </div>
  );
};

export default RegisterPartnersAccountFooter;
