import { useEffect } from "react";
import { useAppStateStore } from "../../store/appState";
import { Form, Input, Button, message, Card } from "antd";
import classes from "./ApplyPrivateSharePermissionPage.module.sass";
import { applyPrivateShareFormType, applyPrivateShareApi } from "../../api";

import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW"; // 使用繁體中文
import dayjs from "dayjs";
import Loading from "../../components/Loading";

const ApplyPrivateSharePermissionPage = () => {
  const { PAGEMODE, USER } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;
  const { accessToken, mobile_number, birthday, realname, email } =
    USER.userState;
  const [form] = Form.useForm();
  useEffect(() => {
    setCurrentPageModeState("applyPrivateSharePermission");
  }, []);

  const submit = async (values: applyPrivateShareFormType) => {
    try {
      const {
        username,
        password,
        password_confirmation,
        mobile_number,
        realname,
        email,
        birthday,
      } = values;
      const handlerDate = (_birthday: string) => {
        const result = dayjs(_birthday).format("YYYY-MM-DD");
        return result;
      };
      const birthdayFormatted = handlerDate(birthday);
      const formData = {
        username,
        password,
        password_confirmation,
        mobile_number,
        realname,
        email,
        birthday: birthdayFormatted,
      };
      if (!accessToken) return message.error("token 不存在");
      const { data, error } = await applyPrivateShareApi(accessToken, formData);
      if (data?.data.success) {
        message.success("申請成功");
      } else {
        const errorMsg = data?.data.message;
        if (errorMsg?.includes("You are already be a private sharer")) {
          message.warn("您已經是私密分享權限的會員", 7);
        } else if (errorMsg?.includes("You must be a paid member firstly")) {
          message.warn(
            "您還未成為正式會員，可在個人資料頁面下方點按〈付費解鎖進階功能〉，感謝您！",
            7
          );
        } else {
          message.error("申請失敗");
        }
      }
      if (error) {
        const errorData = error.response?.data;
        console.log("errorData", errorData);
        message.error("申請失敗");
      }
    } catch (error: any) {
      console.log(error);
      message.error("申請失敗");
    }
  };

  if (!accessToken) return <Loading />;
  return (
    <div className={classes.container}>
      <Card className={classes.card} title="申請私密分享權限">
        <Form
          initialValues={{
            username: "",
            password: "",
            password_confirmation: "",
            mobile_number: mobile_number,
            realname: realname,
            email: email,
            birthday: birthday ? dayjs(birthday) : null,
          }}
          className={classes.registerPartnersAccountPageForm}
          form={form}
          onFinish={submit}
          labelCol={{ span: 6 }} // 控制 label 寬度
          wrapperCol={{ span: 16 }} // 控制輸入框寬度
          labelAlign="right" // label 文字右對齊
        >
          <Form.Item
            name="username"
            label="帳號"
            rules={[
              {
                required: true,
                validator: async (_: unknown, value: string) => {
                  if (!value) {
                    return Promise.reject("請輸入帳號");
                  }

                  if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    return Promise.reject("帳號只能包含英文字母和數字");
                  }

                  if (value.length < 3) {
                    return Promise.reject("帳號長度至少3個字元");
                  }

                  // 檢查是否包含任何空白字元
                  if (/\s/.test(value)) {
                    return Promise.reject("帳號不可包含空白字元");
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="密碼"
            rules={[
              {
                required: true,
                validator: async (_: unknown, value: string) => {
                  if (!value) {
                    return Promise.reject("請輸入密碼");
                  }

                  if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    return Promise.reject("密碼只能包含英文字母和數字");
                  }

                  if (value.length < 6) {
                    return Promise.reject("密碼長度至少6個字元");
                  }

                  // 檢查是否包含任何空白字元
                  if (/\s/.test(value)) {
                    return Promise.reject("密碼不可包含空白字元");
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            label="確認密碼"
            dependencies={["password"]} // 添加依賴，當 password 改變時會重新驗證
            rules={[
              {
                required: true,
                message: "請輸入確認密碼",
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (value.length < 6) {
                    if (value.length < 6) {
                      return Promise.reject("密碼長度至少6個字元");
                    }

                    if (value === getFieldValue("password")) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("兩次輸入的密碼不一致"));
                  }
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="mobile_number"
            label="手機號碼"
            rules={[
              { required: true, message: "請輸入手機號碼" },
              {
                pattern: /^[0-9]{10}$/,
                message: "請輸入有效的手機號碼",
              },
            ]}
            hasFeedback
          >
            <Input
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="realname"
            label="姓名"
            rules={[
              {
                required: true,
                validator: async (_: unknown, value: string) => {
                  if (!value) {
                    return Promise.reject("請輸入姓名");
                  }

                  if (!/^[a-zA-Z\u4E00-\u9FA5\d]+$/.test(value)) {
                    return Promise.reject("姓名只能包含英文字母、中文和數字");
                  }

                  // 檢查是否包含任何空白字元
                  if (/\s/.test(value)) {
                    return Promise.reject("姓名不可包含空白字元");
                  }
                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <Input
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "請輸入 Email",
              },
              {
                type: "email",
                message: "請輸入有效的 Email 格式",
              },
            ]}
            hasFeedback
          >
            <Input
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
            />
          </Form.Item>
          <Form.Item
            name="birthday"
            label="生日"
            rules={[
              {
                required: true,
                message: "請選擇生日",
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  const selectedDate = dayjs(value);
                  const today = dayjs();

                  // 檢查是否超過今天
                  if (selectedDate.isAfter(today)) {
                    return Promise.reject("生日不能晚於今天");
                  }

                  // 檢查年齡是否合理（例如：至少 18 歲）
                  if (today.diff(selectedDate, "year") < 18) {
                    return Promise.reject("年齡必須滿 18 歲");
                  }

                  return Promise.resolve();
                },
              },
            ]}
            hasFeedback
          >
            <DatePicker
              style={{ width: "100%", borderRadius: "0.5rem", height: "42px" }}
              locale={locale}
              format="YYYY-MM-DD"
              placeholder="請選擇生日"
              disabledDate={(current) => {
                // 禁用今天之後的日期
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
          <div className={classes.buttonContainer}>
            <Button className={classes.button} type="primary" htmlType="submit">
              確定送出
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ApplyPrivateSharePermissionPage;
