import classes from "./CustomCategories.module.sass";
import { useEffect, Fragment, useState } from "react";
import { useAppStateStore } from "../../store/appState";
import { Layout, message, Modal } from "antd";

import Header from "./Header";
import CategoryForm from "./CategoryForm";
import CategoryList from "./CategoryList";
import EditModal from "./EditModal";
import Loading from "../Loading";

import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  createNameCardCategories,
  queryUserCategories,
  updateUserCategory,
  deleteUserCategory,
  UserCategoriesDataType,
} from "../../api/category";

import {
  getUserFolderByCategoryId,
  createUserFolder,
  updateUserFolder,
  deleteUserFolder,
  UserFolderDataType,
} from "../../api/folder";

const CustomCategories = () => {
  const { PAGEMODE, USER } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;
  const { Content } = Layout;
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState<UserCategoriesDataType[]>([]);
  const [folders, setFolders] = useState<UserFolderDataType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [isAddingFolder, setIsAddingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState("");
  const [editModalValue, setEditModalValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editModalCallback, setEditModalCallback] = useState<
    (value: string) => void
  >(() => {});

  const { userState } = USER;
  const { accessToken } = userState;

  const { confirm } = Modal;

  /**
   * 查詢名片類別列表
   */
  const queryUserCategoriesData = async (withOfficial: string) => {
    try {
      if (accessToken) {
        const { data } = await queryUserCategories(accessToken, withOfficial);
        // 25.02.26 SCH ★ withOfficial = 2 = not with_official
        console.log(
          "CustomCategories > index.tsx > CustomCategories ",
          "> queryUserCategoriesData > await queryUserCategories",
          "withOfficial = 2"
        );
        console.log("queryUserCategoriesData", data);
        data?.data && setCategories(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log("categories", categories);
  }, [categories]);

  /**
   * 新增名片類別
   */
  const handleAddCategory = async () => {
    try {
      if (newCategoryName.trim() && accessToken) {
        const { data } = await createNameCardCategories(
          accessToken,
          newCategoryName
        );
        if (data?.data.message.includes("Successfully")) {
          message.success("新增名片類別成功");
          queryUserCategoriesData("2"); // 25.02.26 SCH ★
          setNewCategoryName("");
          setIsAddingCategory(false);
        } else if (!data?.data?.success) {
          message.error("新增名片類別失敗");
        }
      }
    } catch (error) {
      console.error(error);
      message.error("新增名片類別失敗");
    }
  };

  /**
   * 新增資料夾
   * @param userCategoryId 名片類別id
   * @param folderName 資料夾名稱
   */
  const handleAddFolder = async (
    userCategoryId: number,
    folderName: string
  ) => {
    try {
      if (folderName.trim() && accessToken) {
        const { data } = await createUserFolder(
          accessToken,
          folderName,
          userCategoryId
        );
        setNewFolderName("");
        setIsAddingFolder(false);
        if (data?.data.message.includes("Successfully")) {
          message.success("新增名片資料夾成功");
          queryUserFoldersDataByCategoryID(userCategoryId);
        } else if (!data?.data?.success) {
          message.error("新增名片資料夾失敗");
        }
      }
    } catch (error) {
      console.error(error);
      message.error("新增名片資料夾失敗");
    }
  };

  /**
   * 編輯類別
   * @param userCategoryName 名片類別名稱
   * @param userCategoryId 名片類別id
   */
  const handleEditCategory = async (
    userCategoryId: number,
    userCategoryName: string
  ) => {
    if (accessToken) {
      try {
        setEditModalTitle("編輯類別名稱");
        setEditModalValue(userCategoryName);
        setEditModalCallback(() => async (newName: string) => {
          const { data } = await updateUserCategory(
            accessToken,
            userCategoryId,
            newName
          );
          console.log("handleEditCategory", data);
          if (data?.data.message.includes("Successfully")) {
            message.success("更新名片類別成功");
            queryUserCategoriesData("2"); // 25.02.26 SCH ★
            setIsEditModalOpen(false);
          } else if (!data?.data?.success) {
            message.error("更新名片類別失敗");
          }
        });
      } catch (error) {
        console.log("error", error);
        message.error("更新名片類別失敗");
      }

      setIsEditModalOpen(true);
    }
  };

  /**
   * 刪除類別
   * @param categoryId 名片類別id
   */
  const handleDeleteCategory = async (categoryId: number) => {
    try {
      if (accessToken) {
        const { data } = await deleteUserCategory(accessToken, categoryId);
        if (data?.data.message.includes("Successfully")) {
          message.success("刪除名片類別成功");
          queryUserCategoriesData("2"); // 25.02.26 SCH ★
        } else if (!data?.data?.success) {
          message.error("刪除名片類別失敗");
        }
      }
    } catch (error) {
      console.error(error);
      message.error("刪除名片類別失敗");
    }
  };

  /**
   * 編輯資料夾
   */
  const handleEditFolder = async (
    userCategoryId: number,
    userFolderId: number,
    folderName: string
  ) => {
    if (accessToken) {
      try {
        setEditModalTitle("編輯資料夾名稱");
        setEditModalValue(folderName);
        setEditModalCallback(() => async (newName: string) => {
          const { data } = await updateUserFolder(
            accessToken,
            userCategoryId,
            userFolderId,
            newName
          );
          if (data?.data.message.includes("Successfully")) {
            message.success("更新名片資料夾成功");
            queryUserFoldersDataByCategoryID(userCategoryId);
            setIsEditModalOpen(false);
          } else if (!data?.data?.success) {
            message.error("更新名片資料夾失敗");
          }
        });
      } catch (error) {
        console.error(error);
        message.error("更新名片資料夾失敗");
      }
      setIsEditModalOpen(true);
    }
  };

  /**
   * 刪除資料夾
   * @param userFolderId 名片資料夾id
   * @param userCategoryId 名片類別id
   */
  const handleDeleteFolder = async (
    userFolderId: number,
    userCategoryId: number
  ) => {
    try {
      if (accessToken) {
        const { data } = await deleteUserFolder(accessToken, userFolderId);
        if (data?.data.message.includes("Successfully")) {
          message.success("刪除名片資料夾成功");
          queryUserFoldersDataByCategoryID(userCategoryId);
        } else if (!data?.data?.success) {
          message.error("刪除名片資料夾失敗");
        }
      }
    } catch (error) {
      console.error(error);
      message.error("刪除名片資料夾失敗");
    }
  };

  /**
   * 查詢該名片類別底下的資料夾列表
   * @param userCategoryId 名片類別id
   */
  const queryUserFoldersDataByCategoryID = async (userCategoryId: number) => {
    try {
      if (accessToken) {
        const { data } = await getUserFolderByCategoryId(
          accessToken,
          userCategoryId
        );
        data?.data && setFolders(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 顯示刪除資料夾確認視窗
   * @param categoryId 類別ID
   * @param folderId 資料夾ID
   */
  const showDeleteFolderConfirm = (
    userFolderId: number,
    userCategoryId: number
  ) => {
    confirm({
      title: "確定要刪除此資料夾嗎？此操作無法復原。",
      icon: <ExclamationCircleFilled rev={""} />,
      onOk() {
        handleDeleteFolder(userFolderId, userCategoryId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "確定",
      cancelText: "取消",
      className: classes.confirmModal,
    });
  };

  /**
   * 顯示刪除類別確認視窗
   * @param categoryId 類別ID
   */
  const showDeleteCategoryConfirm = (categoryId: number) => {
    confirm({
      title: "確定要刪除此類別嗎？此操作無法復原。",
      icon: <ExclamationCircleFilled rev={""} />,
      onOk() {
        handleDeleteCategory(categoryId);
      },
      onCancel() {
        console.log("Cancel");
      },
      okText: "確定",
      cancelText: "取消",
      className: classes.confirmModal,
    });
  };

  // 切換頁面狀態
  useEffect(() => {
    setCurrentPageModeState("customCategories");
    queryUserCategoriesData("2"); // 25.02.26 SCH ★
  }, [accessToken]);

  useEffect(() => {
    selectedCategory && queryUserFoldersDataByCategoryID(selectedCategory);
  }, [selectedCategory]);

  return (
    <Fragment>
      <Header />

      <Content className={classes.content}>
        <div className={classes.cardContainer}>
          <div className={classes.cardContainerContent}>
            <div className={classes.cardContainerContentTitle}>
              <h2 className={classes.cardContainerContentTitleText}>
                全部類別
              </h2>
              <CategoryForm
                isAddingCategory={isAddingCategory}
                newCategoryName={newCategoryName}
                setNewCategoryName={setNewCategoryName}
                handleAddCategory={handleAddCategory}
                setIsAddingCategory={setIsAddingCategory}
              />
            </div>

            <CategoryList
              categories={categories}
              folders={folders}
              setFolders={setFolders}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              isAddingFolder={isAddingFolder}
              newFolderName={newFolderName}
              setNewFolderName={setNewFolderName}
              handleAddFolder={handleAddFolder}
              setIsAddingFolder={setIsAddingFolder}
              onEditCategory={handleEditCategory}
              onDeleteCategory={showDeleteCategoryConfirm}
              onEditFolder={handleEditFolder}
              onDeleteFolder={showDeleteFolderConfirm}
            />
          </div>
        </div>
      </Content>

      <EditModal
        isOpen={isEditModalOpen}
        title={editModalTitle}
        value={editModalValue}
        onClose={() => setIsEditModalOpen(false)}
        onConfirm={editModalCallback}
      />
    </Fragment>
  );
};

export default CustomCategories;
