import { useEffect, useState, Fragment, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  message,
  Collapse,
  Table,
  Divider,
} from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import classes from "./NameCardsList.module.sass";
import { useShareFlexMsg } from "../../../hooks/useShareFlexMsg";
import { useAppStateStore } from "../../../store/appState";
import {
  queryNameCardList,
  nameCardType,
  deleteNameCard,
  assignNameCardToUserApi,
  shareFriendsApi,
  unlinkMeApi,
  privateShareApi,
  removeMeApi,
} from "../../../api/index";
import {
  queryUserCategories,
  UserCategoriesDataType,
} from "../../../api/category";
import {
  getUserFolderByCategoryId,
  UserFolderDataType,
} from "../../../api/folder";
import Loading from "../../Loading";
import NameCardFilters from "./NameCardFilters";
import defaultCoverImg from "../../../image/default-cover.svg";
import NameCardItem from "./NameCardItem";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type NameCardQueryStateType = {
  categoryId?: string;
  folderId?: string;
  keyword?: string;
};

type LocationStateType = {
  queryState?: NameCardQueryStateType;
};

type ModalStateType = {
  show: boolean;
  cardId: number | null;
};

type AssignModeType = {
  mode: "assign" | "shareFriends";
  show: boolean;
  cardId: number | null;
};

type AssignNameCardModalFormType = {
  mobile_number: string;
  sales_mobile: string;
};

type ShareFriendsFormType = {
  friend_mobiles?: string[];
  sales_mobile?: string;
  delete_mobiles?: string;
};

const NameCardsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { shareNameCard, error } = useShareFlexMsg();
  const [nameCards, setNameCards] = useState<nameCardType[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { APP, BRAND, USER, PAGEMODE, NAMECARD_DATA } = useAppStateStore(); // 24.11.01 SCH ★
  const { appState, setAppState, currentConfig } = APP;
  const { brandState } = BRAND; // 24.11.01 SCH ★
  const { userState } = USER;
  const { accessToken } = userState;
  const { setCurrentPageModeState } = PAGEMODE;
  const {
    initNameCardV1Data,
    initNameCardV1Setting,
    initNameCardV3Data,
    initNameCardV3Setting,
    initCurrentCategory,
    initNameCardV1Form,
    setNameCardV3CurrentPage,
    setNameCardV1CurrentActiveKey,
  } = NAMECARD_DATA;
  const [errorMsg, setErrorMsg] = useState<{
    errMsg: string;
    error: boolean;
  }>({ errMsg: "", error: false });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<ModalStateType>({
    show: false,
    cardId: null,
  });
  const [form] = Form.useForm();
  // 初始化 queryState 從 location.state 中 取得
  const { queryState: initialQueryState } =
    (location.state as LocationStateType) || {};

  const { phoneLength, role3Friends, role4Friends, role5Friends } = brandState; // 25.02.25 SCH
  const { role, eagle, editable, trial_due_at } = userState; // 25.01.28 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.10.31 SCH ★
  const [banLoign, setBanLoign] = useState(false); // 24.10.31 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isUpdateBanModalOpen, setIsUpdateBanModalOpen] = useState(false); // 25.02.19 SCH ★
  const [isDeleteBanModalOpen, setIsDeleteBanModalOpen] = useState(false); // 25.02.19 SCH ★
  const [isShareBanModalOpen, setIsShareBanModalOpen] = useState(false); // 25.03.02 SCH ★
  const [isPrivateShareBanModalOpen, setIsPrivateShareBanModalOpen] =
    useState(false); // 25.03.03 SCH ★
  const [isHintModalOpen, setIsHintModalOpen] = useState(false); // 24.11.21 SCH ★
  const [isHint2ModalOpen, setIsHint2ModalOpen] = useState(false); // 25.01.02 SCH ★
  const [isHint3ModalOpen, setIsHint3ModalOpen] = useState(false); // 25.03.08 SCH ★
  const [queryState, setQueryState] = useState<NameCardQueryStateType>({});
  const [tempQueryState, setTempQueryState] = useState<NameCardQueryStateType>(
    initialQueryState || {}
  );
  const [categoryList, setCategoryList] = useState<UserCategoriesDataType[]>(
    []
  );
  const totalCount = useRef("0"); // 25.02.25 SCH ★
  // 25.03.03 SCH ★
  const [privateShareModalState, setPrivateShareModalState] =
    useState<ModalStateType>({
      show: false,
      cardId: null,
    });
  const [folderList, setFolderList] = useState<UserFolderDataType[]>([]);
  const [assignNameCardModalState, setAssignNameCardModalState] =
    useState<AssignModeType>({
      mode: "assign",
      show: false,
      cardId: null,
    });

  const [assignMode, setAssignMode] = useState<"assign" | "shareFriends">(
    "assign"
  );

  const handleAssignModeChange = (mode: "assign" | "shareFriends") => {
    setAssignMode(mode);
  };

  const [currentNameCardData, setCurrentNameCardData] =
    useState<nameCardType | null>(null);

  const [selectedFriendsForDeletion, setSelectedFriendsForDeletion] = useState<
    SelectedFriendsForDeletionType[]
  >([]);

  type SelectedFriendsForDeletionType = {
    key: string;
    rowID: number;
    name: string;
    phone: string;
  };

  const friendDataColumns = [
    {
      title: "rowID",
      dataIndex: "rowID",
    },
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "手機",
      dataIndex: "phone",
    },
  ];

  /**
   * 查詢名片類別
   */
  const queryCategoryList = async (withOfficial: string) => {
    try {
      if (accessToken) {
        const { data: res } = await queryUserCategories(
          accessToken,
          withOfficial
        );
        // 25.02.26 SCH ★ withOfficial = 2 = not with_official
        // NameCardsList ★ withOfficial = 1 = with_official ★
        console.log(
          "Nember > NameCardList > index.tsx > NameCardList ",
          "> queryCategoryList > await queryUserCategories",
          "withOfficial = 1"
        );
        res?.data && console.log("queryUserCategories", res.data);
        res?.data && setCategoryList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 查詢資料夾 by categoryId 名片類別ID
   * @param categoryId 名片類別ID
   */
  const queryFolderList = async (categoryId: number) => {
    try {
      if (accessToken) {
        const { data: res } = await getUserFolderByCategoryId(
          accessToken,
          categoryId
        );
        res?.data && setFolderList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //有 line_id 就用 line_id 要名片資料, 沒有 line_id 就使用 mobile_number 來要名片列表資料
  const getUerNameCards = async () => {
    setAppState({ isLoading: true });
    console.log("queryState", queryState);

    try {
      if (accessToken && userState) {
        // 25.01.03 SCH ★★★ add [line_id || ]
        const { line_id, mobile_number } = userState;
        if (line_id || mobile_number) {
          const { data: res } = await queryNameCardList(
            accessToken,
            undefined,
            line_id,
            queryState.categoryId,
            queryState.folderId,
            queryState.keyword
          );
          if (res) {
            const { data } = res;
            console.log("name_cards", data);
            setNameCards(data);
            totalCount.current = res.total_count.toString();
            console.log("total_count", totalCount.current);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      setAppState({ isLoading: false });
    }
  };

  const DeleteNameCard = async (_card_id: number) => {
    try {
      if (accessToken) {
        setAppState({ isLoading: true });

        console.log("name_cards", `刪除${_card_id}`);
        const { data, error } = await deleteNameCard(accessToken, _card_id);
        if (data) {
          const { success } = data.data;
          if (success) {
            message.success("刪除名片成功");
            setTimeout(() => {
              window.location.reload(); // 25.02.24 SCH ★
            }, 3000);
          } else {
            message.error("刪除名片失敗");
          }
        }
        if (error) {
          const errorData = JSON.stringify(error?.response?.data);
          if (errorData.includes("not permitted to delete")) {
            message.warn("您沒有權限刪除名片", 7);
          } else {
            message.error("刪除名片失敗");
          }
        }
      }
    } catch (error) {
      setErrorMsg({ errMsg: JSON.stringify(error), error: true });
      console.log(error);
    }
  };

  /**
   * 指派數位名片給其他用戶
   * @param _card_id 數位名片ID
   * @param _mobile_number 被指派的那個 user 手機號碼
   * @param _sales_mobile 介紹業務的介紹人手機號碼
   */
  const assignNameCardToUser = async (
    _card_id: number | null,
    values: AssignNameCardModalFormType
  ) => {
    try {
      if (!_card_id) return message.error("指派的名片ID 不能為空");
      const { mobile_number, sales_mobile } = values;
      if (accessToken) {
        const { data, error } = await assignNameCardToUserApi(
          accessToken,
          _card_id,
          mobile_number,
          sales_mobile
        );
        if (data) {
          const { success } = data.data;
          if (success) {
            message.success("指派名片成功");
            setTimeout(() => {
              window.location.reload(); // 25.02.24 SCH ★
            }, 3000);
            closeAssignNameCardModal();
          } else {
            message.error("指派名片失敗");
            closeAssignNameCardModal();
          }
        }

        if (error) {
          const errorData = JSON.stringify(error?.response?.data);
          if (errorData.includes("not allowed to assign")) {
            message.warn("您沒有權限指派名片", 7);
          } else if (errorData.includes("are not the owner")) {
            message.warn("您不是名片的擁有者", 7);
          } else {
            message.error("指派名片失敗");
          }
          closeAssignNameCardModal();
        }
      }
    } catch (error) {
      message.error("指派名片操作失敗");
      closeAssignNameCardModal();
      console.log(error);
    }
  };

  /**
   * 分享數位名片給多位其他用戶
   * @param _card_id 數位名片ID
   * @param friend_mobiles 被分享的那個 user 手機號碼陣列
   * @param _sales_mobile 介紹業務的介紹人手機號碼
   */
  const nameCardShareFriends = async (
    _card_id: number | null,
    values: ShareFriendsFormType
  ) => {
    try {
      if (!_card_id) return message.error("分享的名片ID 不能為空");
      if (!accessToken) return message.error("accessToken 不能為空");

      // 如果有要刪除的好友，先執行刪除操作
      if (values.delete_mobiles) {
        const deleteFormData = {
          sales_mobile: values.sales_mobile || undefined,
          delete_mobiles: values.delete_mobiles,
        };

        const { data: deleteData, error: deleteError } = await shareFriendsApi(
          accessToken,
          _card_id,
          deleteFormData
        );

        if (deleteError) {
          const errorData = JSON.stringify(deleteError?.response?.data);
          if (errorData.includes("are not the owner")) {
            message.warn("僅此名片的擁有者，才有權限刪除喔！", 7);
          } else if (errorData.includes("You must be a paid member firstly")) {
            message.warn(
              "您沒有權限刪除名片，可在個人資料頁面下方點按〈付費解鎖進階功能〉，感謝您！",
              7
            );
          } else if (errorData.includes("phones not found")) {
            message.warn("有手機號碼不存在官網", 7);
          } else if (errorData.includes("phones duplicated")) {
            message.warn("有重複的手機號碼喔", 7);
          } else if (errorData.includes("phones non shared")) {
            message.warn("手機號碼必須是已分享過的", 7);
          } else {
            message.error("取消分享好友失敗");
          }
          // setAppState({ isLoading: false });
          closeAssignNameCardModal();
          return;
        }

        if (!deleteData?.data?.success) {
          message.error("取消分享好友失敗");
          // setAppState({ isLoading: false });
          closeAssignNameCardModal();
          return;
        }

        if (deleteData?.data?.success) {
          message.success("取消分享好友成功");
          closeAssignNameCardModal();
          getUerNameCards();
        }
      }

      if (values.friend_mobiles && values.friend_mobiles?.length > 0) {
        // 準備分享的資料
        const shareFormData = {
          friend_mobiles: values.friend_mobiles
            ? values.friend_mobiles.join(",")
            : undefined,
          sales_mobile: values.sales_mobile || undefined,
        };

        const { data, error } = await shareFriendsApi(
          accessToken,
          _card_id,
          shareFormData
        );
        if (data) {
          const { success } = data.data;
          if (success) {
            message.success("分享名片成功");

            closeAssignNameCardModal();
            getUerNameCards();
          } else {
            message.error("分享名片失敗");
            closeAssignNameCardModal();
          }
        }
        // 25.02.23 SCH ★★★
        if (error) {
          const errorData = JSON.stringify(error?.response?.data);
          if (errorData.includes("You must be a paid member firstly")) {
            message.warn(
              "您沒有權限分享名片，可在個人資料頁面下方點按〈付費解鎖進階功能〉，感謝您！",
              7
            );
          } else if (errorData.includes("are not the owner")) {
            message.warn("僅此名片的擁有者，才有權限分享喔！", 7);
          } else if (errorData.includes("phones not found")) {
            message.warn("有手機號碼不存在官網", 7);
          } else if (errorData.includes("phones duplicated")) {
            message.warn("有重複的手機號碼喔", 7);
          } else if (errorData.includes("phones has been share")) {
            message.warn("手機號碼必須是未分享過的", 7);
          } else {
            message.error("分享名片失敗");
          }
          closeAssignNameCardModal();
        }
      }
    } catch (error) {
      message.error("分享朋友圈操作失敗");
      closeAssignNameCardModal();
      console.log("error", error);
    }
  };

  /**
   * unlinkMe(解除連結)
   * @param _card_id 數位名片ID
   * @param delete_mobiles 要解除連結的那個 user 手機號碼
   */
  const nameCardUnlinkMe = async (
    _card_id: number | null,
    values: ShareFriendsFormType
  ) => {
    try {
      if (!_card_id) return message.error("分享的名片ID 不能為空");
      if (!accessToken) return message.error("accessToken 不能為空");

      // unlinkMe(解除連結)
      const deleteFormData = {
        sales_mobile: values.sales_mobile || undefined,
        delete_mobiles: values.delete_mobiles,
      };

      const { data: deleteData, error: deleteError } = await unlinkMeApi(
        accessToken,
        _card_id,
        deleteFormData
      );

      if (deleteError) {
        const errorData = JSON.stringify(deleteError?.response?.data);
        if (errorData.includes("id not found")) {
          message.warn("card_id 不存在！", 7);
        } else {
          message.error("解除連結失敗");
        }
        setIsDeleteBanModalOpen(false);
        return;
      }

      if (!deleteData?.data?.success) {
        message.error("解除連結失敗");
        setIsDeleteBanModalOpen(false);
        return;
      }

      if (deleteData?.data?.success) {
        message.success("已解除連結！");
        setIsDeleteBanModalOpen(false);
        getUerNameCards();
      }
    } catch (error) {
      message.error("解除連結操作失敗");
      setIsDeleteBanModalOpen(false);
      console.log("error", error);
    }
  };

  /**
   * privateShare(私密分享)
   * @param _card_id 數位名片ID
   * @param _mobile_number 被私密分享的那個 user 手機號碼
   */
  const nameCardPrivateShare = async (
    _card_id: number | null,
    values: AssignNameCardModalFormType
  ) => {
    try {
      if (!_card_id) return message.error("指派的名片ID 不能為空");
      const { mobile_number } = values;
      if (accessToken) {
        const { data, error } = await privateShareApi(
          accessToken,
          _card_id,
          mobile_number
        );
        if (data) {
          const { success } = data.data;
          if (success) {
            message.success("私密分享成功");
            setTimeout(() => {
              window.location.reload(); // 25.03.03 SCH ★
            }, 3000);
            closePrivateShareModal();
          } else {
            message.error("私密分享失敗");
            closePrivateShareModal();
          }
        }

        if (error) {
          const errorData = JSON.stringify(error?.response?.data);
          if (errorData.includes("not allowed to private share")) {
            message.warn("您沒有權限私密分享名片", 7);
          } else if (errorData.includes("are not the owner")) {
            message.warn("您不是名片的擁有者", 7);
          } else if (errorData.includes("mobile_number not found")) {
            message.warn("手機號碼不存在官網", 7);
          } else if (errorData.includes("id not found")) {
            message.warn("card_id 不存在！", 7);
          } else {
            message.error("私密分享失敗");
          }
          closePrivateShareModal();
        }
      }
    } catch (error) {
      message.error("私密分享操作失敗");
      closePrivateShareModal();
      console.log(error);
    }
  };

  /**
   * removeMe(將我移除)
   * @param _card_id 數位名片ID
   */
  const nameCardRemoveMe = async (_card_id: number | null) => {
    try {
      if (!_card_id) return message.error("分享的名片ID 不能為空");
      if (!accessToken) return message.error("accessToken 不能為空");

      const { data: deleteData, error: deleteError } = await removeMeApi(
        accessToken,
        _card_id
      );

      if (deleteError) {
        const errorData = JSON.stringify(deleteError?.response?.data);
        if (errorData.includes("id not found")) {
          message.warn("card_id 不存在！", 7);
        } else if (errorData.includes("not the same member")) {
          message.warn("您非被私密分享者本人", 7);
        } else {
          message.error("移除連結失敗");
        }
        setIsDeleteBanModalOpen(false);
        return;
      }

      if (!deleteData?.data?.success) {
        message.error("移除連結失敗");
        setIsDeleteBanModalOpen(false);
        return;
      }

      if (deleteData?.data?.success) {
        message.success("已移除連結！");
        setIsDeleteBanModalOpen(false);
        getUerNameCards();
      }
    } catch (error) {
      message.error("移除連結操作失敗");
      setIsDeleteBanModalOpen(false);
      console.log("error", error);
    }
  };

  const getCoverImg = (_image_urls: string[]) => {
    if (!_image_urls || _image_urls?.length === 0) return defaultCoverImg;
    return `${currentConfig.REACT_APP_IMAGEBASEURL}${_image_urls[0]}`;
  };

  const handleCategoryChange = (categoryId: string | undefined) => {
    const newTempQueryState = categoryId ? { categoryId } : {};
    console.log("newTempQueryState", newTempQueryState);
    setTempQueryState(newTempQueryState);
  };

  const handleFolderChange = (folderId: string | undefined) => {
    const newTempQueryState = {
      ...tempQueryState,
      folderId,
    };
    if (!folderId) {
      delete newTempQueryState.folderId;
    }
    setTempQueryState(newTempQueryState);
  };

  const handleKeywordChange = (keyword: string) => {
    const newTempQueryState = {
      ...tempQueryState,
      keyword: keyword.trim() || undefined,
    };
    if (!newTempQueryState.keyword) {
      delete newTempQueryState.keyword;
    }
    setTempQueryState(newTempQueryState);
  };

  const handleConfirmFilter = () => {
    setQueryState(tempQueryState);
    navigate(".", { state: { queryState: tempQueryState }, replace: true });
  };

  const handleClearFilters = () => {
    const emptyState = {};
    setQueryState(emptyState);
    setTempQueryState(emptyState);
    setFolderList([]);
    navigate(".", { state: { queryState: emptyState }, replace: true });
  };

  const hasActiveFilters = Boolean(
    queryState.categoryId || queryState.folderId || queryState.keyword
  );

  /**
   * 編輯名片的處理
   * @param _cardId 名片ID
   */
  const handleEdit = (_cardId: number) => {
    if (role >= 2 || editable || eagle) {
      navigate(`/update/${_cardId}`);
    } else {
      setIsEditBanModalOpen(true);
    }
  };

  /**
   * 修改名片被禁止的處理 // 25.02.19 SCH
   */
  const handleUpdateBaned = () => {
    console.log("name_cards", `修改名片被禁止的處理`);
    setIsUpdateBanModalOpen(true);
  };

  /**
   * 刪除名片的處理
   * @param _cardId 名片ID
   */
  const handleDelete = (_cardId: number) => {
    setIsDeleteModalOpen({
      show: true,
      cardId: _cardId,
    });
  };

  /**
   * 刪除名片被禁止的處理 // 25.02.19 SCH
   */
  const handleDeleteBaned = (carID: number) => {
    console.log("name_cards", `刪除名片被禁止的處理`);
    setIsDeleteBanModalOpen(true);
    if (nameCards) {
      const temp = nameCards.find((item) => item.card_id === carID);
      temp && setCurrentNameCardData(temp);
    }
  };

  /**
   * 分享名片被禁止的處理 // 25.03.02 SCH
   */
  const handleShareBaned = (carID: string) => {
    console.log("name_cards", `分享名片被禁止的處理`);
    setIsShareBanModalOpen(true);
  };

  const handlePrivateShare = (card_id: number) => {
    if (nameCards) {
      const selectedNameCardData = nameCards.find(
        (item) => item.card_id === card_id
      );
      console.log("selectedNameCardData", selectedNameCardData);
      selectedNameCardData && setCurrentNameCardData(selectedNameCardData);
      selectedNameCardData &&
        form.setFieldsValue({
          mobile_number: "",
        });
    }
    setPrivateShareModalState({
      show: true,
      cardId: card_id,
    });
  };

  /**
   * 私密分享被禁止的處理 // 25.03.03 SCH
   */
  const handlePrivateShareBaned = () => {
    console.log("name_cards", `私密分享被禁止的處理`);
    setIsPrivateShareBanModalOpen(true);
  };

  const handleNameCardAssignment = (_cardId: number) => {
    if (nameCards) {
      const selectedNameCardData = nameCards.find(
        (item) => item.card_id === _cardId
      );
      console.log("selectedNameCardData", selectedNameCardData);
      selectedNameCardData && setCurrentNameCardData(selectedNameCardData);
      selectedNameCardData &&
        form.setFieldsValue({
          sales_mobile: selectedNameCardData.partner_mobile
            ? selectedNameCardData.sales_mobile
            : "",
        });
    }
    setAssignNameCardModalState({
      show: true,
      cardId: _cardId,
      mode: assignMode,
    });
  };

  const closeAssignNameCardModal = () => {
    setAssignNameCardModalState({
      show: false,
      cardId: null,
      mode: "assign",
    });
    form.resetFields();
    setCurrentNameCardData(null);
    setSelectedFriendsForDeletion([]);
  };

  const closePrivateShareModal = () => {
    setPrivateShareModalState({
      show: false,
      cardId: null,
    });
    form.resetFields();
    setCurrentNameCardData(null);
  };

  // 錯誤提示的 Modal 確定
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // 錯誤提示的 Modal 關閉
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // 刪除名片的 Modal 確定
  const okDeleteModalHandle = () => {
    console.log(isDeleteModalOpen.cardId);
    if (isDeleteModalOpen.cardId) {
      DeleteNameCard(isDeleteModalOpen.cardId);
    }
    setIsDeleteModalOpen({
      show: false,
      cardId: null,
    });
  };

  // 刪除名片的 Modal 關閉
  const cancelDeleteModalHandle = () => {
    setIsDeleteModalOpen({
      show: false,
      cardId: null,
    });
  };

  // 提示免費試用期限的 Modal 確定
  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  // 提示免費試用期限的 Modal 關閉
  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  // 修改名片被禁止的 Modal 確定 // 25.02.19 SCH
  const UpdateBanHandleOk = () => {
    setIsUpdateBanModalOpen(false);
  };

  // 修改名片被禁止的 Modal 關閉 // 25.02.19 SCH
  const UpdateBanHandleCancel = () => {
    setIsUpdateBanModalOpen(false);
  };

  // 刪除名片被禁止的 Modal 確定 // 25.02.19 SCH
  const DeleteBanHandleOk = () => {
    setIsDeleteBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 刪除名片被禁止的 Modal 關閉 // 25.02.19 SCH
  const DeleteBanHandleCancel = () => {
    setIsDeleteBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 分享名片被禁止的 Modal 確定 // 25.03.02 SCH
  const ShareBanHandleOk = () => {
    setIsShareBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 分享名片被禁止的 Modal 關閉 // 25.03.02 SCH
  const ShareBanHandleCancel = () => {
    setIsShareBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 私密分享被禁止的 Modal 確定 // 25.03.03 SCH
  const PrivateShareBanHandleOk = () => {
    setIsPrivateShareBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 私密分享被禁止的 Modal 關閉 // 25.03.03 SCH
  const PrivateShareBanHandleCancel = () => {
    setIsPrivateShareBanModalOpen(false);
    setCurrentNameCardData(null);
  };

  // 新手會員提醒的 Modal 確定
  const HintHandleOk = () => {
    setIsHintModalOpen(false);
  };
  // 新手會員提醒的 Modal 關閉
  const HintHandleCancel = () => {
    setIsHintModalOpen(false);
  };

  // 會員二度提醒的 Modal 確定
  const Hint2HandleOk = () => {
    setIsHint2ModalOpen(false);
  };
  // 會員二度提醒的 Modal 關閉
  const Hint2HandleCancel = () => {
    setIsHint2ModalOpen(false);
  };

  // 聯盟行銷說明的 Modal 確定
  const Hint3HandleOk = () => {
    setIsHint3ModalOpen(false);
  };
  // 聯盟行銷說明的 Modal 關閉
  const Hint3HandleCancel = () => {
    setIsHint3ModalOpen(false);
  };

  // 檢查免費試用期限
  useEffect(() => {
    if (accessToken && userState) {
      const trialDueAt = trial_due_at?.toString();
      if (trialDueAt) setTrialDueAt(trialDueAt); // 24.10.31 SCH ★
      const now_date = format(new Date(), "yyyy-MM-dd");
      if (role < 2 && trialDueAt !== undefined) {
        if (brandState === undefined) {
          // 沒抓到資料！
          console.log("brandState", brandState);
          if (now_date > trialDueAt && !eagle) {
            setBanLoign(true); // 24.10.31 SCH ★
          }
        } else {
          // 有抓到資料！ // 24.11.01 SCH ★
          console.log("brandState", brandState);
          if (brandState.use_trial_due_at && now_date > trialDueAt && !eagle) {
            if (!brandState.use_login_log_count) {
              setBanLoign(true); // 24.11.01 SCH ★
            } else {
              if (brandState.login_log_count > brandState.trial_login_times) {
                setBanLoign(true); // 24.11.01 SCH ★
              }
            }
          }
        }
      }
    }

    if (accessToken && userState) {
      setTimeout(() => {
        getUerNameCards();
        queryCategoryList("1"); // 25.02.26 SCH ★
        setAppState({ isLoading: false });
      }, 500);
    }
  }, [
    accessToken,
    userState,
    appState.isLoading,
    brandState,
    eagle,
    role,
    trial_due_at,
  ]); // 25.01.28 SCH ★

  useEffect(() => {
    // 如果 tempQueryState 不存在，則不執行
    if (!tempQueryState) return;
    // 如果 tempQueryState.categoryId 不存在，則不執行
    if (!tempQueryState.categoryId) return;
    // 將 tempQueryState.categoryId 轉換為數字
    const categoryId = Number(tempQueryState.categoryId);
    // 確認 categoryId 是數字
    if (typeof categoryId !== "number" || isNaN(categoryId)) return;
    // query 資料夾列表
    queryFolderList(categoryId);
  }, [tempQueryState, tempQueryState.categoryId, accessToken]);

  // 初始化 nameCard V1 & V3 的狀態
  // 設定現在的頁面是 nameCardsList
  useEffect(() => {
    setCurrentPageModeState("nameCardsList");
    // 初始化 nameCard V1 的狀態
    initNameCardV1Data();
    // 初始化 nameCard V1 的設定
    initNameCardV1Setting();
    // 初始化 nameCard V3 的狀態
    initNameCardV3Data();
    // 初始化 nameCard V3 的設定
    initNameCardV3Setting();
    // 初始化 currentCategory 的狀態
    initCurrentCategory();
    // 初始化 nameCard V1 的表單
    initNameCardV1Form();
    // 初始化 nameCard V3 的頁面
    setNameCardV3CurrentPage(0);
    // 初始化 nameCard V1 的頁面 key
    setNameCardV1CurrentActiveKey("newTab-1");
  }, []);

  // error 狀態是 share 名片時的錯誤
  // 當 分享名片 發生錯誤時，開啟 錯誤提示的 Modal
  useEffect(() => {
    error && setIsModalOpen(true);
  }, [error]);

  // 初始化 queryState 從 location.state 中 取得
  useEffect(() => {
    if (initialQueryState) {
      setQueryState(initialQueryState);
      setTempQueryState(initialQueryState);
    }
  }, [initialQueryState]);

  // 如果 userState.mobile_number 是 null，則開啟新手提醒的 Modal
  useEffect(() => {
    if (userState.mobile_number === null) {
      // 25.01.03 SCH ★ (一般會員可免費試建幾張名片？會員二度提醒)
      if (userState.name_card_count >= brandState.trial_name_card3) {
        if (userState.name_card_count > 3) {
          // 25.03.08 SCH ★ (一般會員可免費試建幾張名片？聯盟行銷說明)
          setIsHint3ModalOpen(true); // 25.03.08 SCH ★
        } else {
          setIsHint2ModalOpen(true); // 25.01.03 SCH ★
        }
      } else {
        // 25.01.03 SCH ★ (提醒新手會員觸按右上角「十」按鈕，進入「新增名片」頁面)
        if (userState.name_card_count === 0) {
          setIsHintModalOpen(true); // 24.12.04 SCH ★
        } else if (userState.name_card_count > 3) {
          // 25.03.08 SCH ★ (一般會員可免費試建幾張名片？聯盟行銷說明)
          setIsHint3ModalOpen(true); // 25.03.08 SCH ★
        } else {
          // 25.03.08 SCH ★ (一般會員可免費試建幾張名片？會員二度提醒)
          setIsHint2ModalOpen(true); // 25.03.08 SCH ★
        }
      }
    }
  }, [userState, brandState]);

  // 捕獲 queryState 的狀態變化，狀態改變時，查詢名片列表
  useEffect(() => {
    getUerNameCards();
  }, [queryState]);

  if (errorMsg.error === true) return <div>{errorMsg.errMsg}</div>;
  return (
    <Fragment>
      <NameCardFilters
        categories={categoryList}
        folders={folderList}
        selectedCategory={tempQueryState.categoryId}
        selectedFolder={tempQueryState.folderId}
        keyword={tempQueryState.keyword}
        onKeywordChange={handleKeywordChange}
        onCategoryChange={handleCategoryChange}
        onFolderChange={handleFolderChange}
        onConfirmFilter={handleConfirmFilter}
        onClearFilters={handleClearFilters}
        hasActiveFilters={hasActiveFilters}
      />
      {appState.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          {!appState.isLoading &&
          nameCards !== undefined &&
          nameCards.length === 0 ? (
            <h3>
              沒有找到任何電子名片，您可以嘗試調整搜尋條件或新增電子名片。
            </h3>
          ) : banLoign ? (
            <h3>您已逾免費試用期限（{`${trialDueAt}`}）喔！</h3> // 24.10.31 SCH ★
          ) : (
            <>
              <p>名片數量︰{`${totalCount.current}`}</p>
              {nameCards &&
                nameCards.map((item) => (
                  <NameCardItem
                    key={item.card_id}
                    imageUrl={getCoverImg(item.image_urls)}
                    cardId={item.card_id}
                    cardTitle={item.card_title}
                    updatedAt={item.updated_at}
                    createdAt={item.created_at}
                    editHandler={
                      item.updateEnable !== 1 ? handleUpdateBaned : handleEdit
                    }
                    deleteHandler={
                      item.deleteEnable !== 1 ? handleDeleteBaned : handleDelete
                    }
                    shareHandler={
                      item.shareEnabled !== 1 ? handleShareBaned : shareNameCard
                    }
                    shareFriends={item.shareFriends === 1}
                    assignEnable={item.assignEnable === 1}
                    assignHandler={() => handleNameCardAssignment(item.card_id)}
                    handleAssignModeChange={handleAssignModeChange}
                    privateShare={item.privateShare === 1}
                    privateShareHandler={() =>
                      item.privateShare !== 1
                        ? handlePrivateShareBaned
                        : handlePrivateShare(item.card_id)
                    }
                  />
                ))}
            </>
          )}
        </div>
      )}

      <Modal
        title={
          assignMode === "assign"
            ? "指派數位名片給其他用戶"
            : "分享朋友圈 - 指派數位名片給多位用戶"
        }
        open={assignNameCardModalState.show}
        onOk={closeAssignNameCardModal}
        onCancel={closeAssignNameCardModal}
        footer={false}
      >
        <Form
          className={classes.assignModal}
          form={form}
          initialValues={{
            mobile_number: "",
            mobile_numbers: [],
            sales_mobile: currentNameCardData?.partner_mobile
              ? currentNameCardData?.sales_mobile
              : "",
          }}
          onError={(errorInfo) => {
            console.log("errorInfo", errorInfo);
          }}
          layout="vertical"
          onFinish={(values) => {
            if (assignMode === "assign") {
              assignNameCardToUser(assignNameCardModalState.cardId, values);
            } else {
              nameCardShareFriends(assignNameCardModalState.cardId, {
                ...values,
                delete_mobiles: selectedFriendsForDeletion
                  ?.map((item) => item.phone)
                  ?.join(","),
              });
            }
          }}
        >
          <div className={classes.assignModalContent}>
            {currentNameCardData?.salesEnabled === 1 && (
              <Form.Item
                className={classes.input}
                name="sales_mobile"
                label={
                  <span className={classes.formLabel}>
                    轉介業務手機(非必填)
                  </span>
                }
                rules={[
                  {
                    required: assignMode === "assign",
                    message: "請輸入轉介業務手機號碼",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "請輸入有效的手機號碼",
                  },
                ]}
              >
                <div className={classes.formInput}>
                  <Input placeholder="請輸入轉介業務的介紹人手機號碼" />
                </div>
              </Form.Item>
            )}
            {assignMode === "assign" && (
              <Form.Item
                className={classes.input}
                name="mobile_number"
                label={<span className={classes.formLabel}>委託會員手機</span>}
                rules={[
                  {
                    required: assignMode === "assign",
                    message: "請輸入委託會員手機號碼",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "請輸入有效的手機號碼",
                  },
                ]}
              >
                <div className={classes.formInput}>
                  <Input placeholder="請輸入被指派的用戶手機號碼" />
                </div>
              </Form.Item>
            )}
            {assignMode === "shareFriends" && (
              <>
                <div className={classes.friendsLabel}>好友手機號碼</div>
                <Form.List name="friend_mobiles">
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        {fields.map((field) => (
                          <Form.Item key={field.key}>
                            <Form.Item
                              noStyle
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: "請輸入委託會員手機號碼",
                                },
                                {
                                  pattern: /^[0-9]{10}$/,
                                  message: "請輸入有效的手機號碼",
                                },
                              ]}
                            >
                              <div className={classes.inputWithRemove}>
                                <div className={classes.formInput}>
                                  <Input placeholder="請輸入被指派的用戶手機號碼" />
                                </div>
                                <div>
                                  <MinusCircleOutlined
                                    size={24}
                                    rev={""}
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </div>
                            </Form.Item>
                          </Form.Item>
                        ))}
                        <div className={classes.addFriendButton}>
                          <Button
                            type="link"
                            disabled={
                              fields.length >=
                              (role >= 5
                                ? role5Friends
                                : role >= 4
                                  ? role4Friends
                                  : role >= 3
                                    ? role3Friends
                                    : 20)
                            }
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined rev={""} />}
                          >
                            {fields.length >=
                            (role >= 5
                              ? role5Friends
                              : role >= 4
                                ? role4Friends
                                : role >= 3
                                  ? role3Friends
                                  : 20)
                              ? "已達到最大分享數量"
                              : "新增要分享的好友手機號碼"}
                          </Button>
                        </div>
                      </>
                    );
                  }}
                </Form.List>
              </>
            )}
          </div>

          {assignMode === "shareFriends" && (
            <>
              {" "}
              <Divider />
              <Collapse className={classes.collapse} defaultActiveKey={["1"]}>
                <Collapse.Panel
                  header={
                    <span className={classes.collapseHeader}>
                      已分享好友名單 (勾選要取消分享的好友)
                    </span>
                  }
                  key="1"
                >
                  {currentNameCardData &&
                  currentNameCardData?.friends_data?.data?.length > 0 ? (
                    <Table
                      className={classes.table}
                      rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: selectedFriendsForDeletion.map(
                          (item) => item.key
                        ),
                        onChange: (selectedRowKeys, selectedRows) => {
                          setSelectedFriendsForDeletion(selectedRows);
                        },
                      }}
                      pagination={{
                        pageSize: 20,
                        showTotal: (total) => `共 ${total} 位好友`,
                      }}
                      columns={friendDataColumns}
                      dataSource={currentNameCardData?.friends_data?.data?.map(
                        (friend, index) => ({
                          key: `${currentNameCardData.card_id}-${index}`,
                          rowID: index,
                          name: friend.name,
                          phone: friend.phone,
                        })
                      )}
                    />
                  ) : (
                    <p>目前沒有任何好友</p>
                  )}
                </Collapse.Panel>
              </Collapse>
            </>
          )}
          <div>
            <Button
              className={classes.button}
              type="primary"
              ghost
              htmlType="submit"
            >
              <div className={classes.buttonWord}>確定執行</div>
            </Button>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={closeAssignNameCardModal}
            >
              <div className={classes.buttonWord}>取消</div>
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"私密分享好友"}
        open={privateShareModalState.show}
        onOk={closePrivateShareModal}
        onCancel={closePrivateShareModal}
        footer={false}
      >
        <Form
          className={classes.assignModal}
          form={form}
          initialValues={{
            mobile_number: "",
            mobile_numbers: [],
            sales_mobile: "",
          }}
          onError={(errorInfo) => {
            console.log("errorInfo", errorInfo);
          }}
          layout="vertical"
          onFinish={(values) => {
            nameCardPrivateShare(privateShareModalState.cardId, values);
          }}
        >
          <div className={classes.assignModalContent}>
            <Form.Item
              className={classes.input}
              name="mobile_number"
              label={
                <span className={classes.formLabel}>私密分享好友手機</span>
              }
              rules={[
                {
                  required: true,
                  message: "請輸入私密分享好友手機號碼",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "請輸入有效的手機號碼",
                },
              ]}
            >
              <div className={classes.formInput}>
                <Input placeholder="請輸入私密分享好友手機號碼" />
              </div>
            </Form.Item>
          </div>
          <div>
            <Button
              className={classes.button}
              type="primary"
              ghost
              htmlType="submit"
            >
              <div className={classes.buttonWord}>確定執行</div>
            </Button>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={closePrivateShareModal}
            >
              <div className={classes.buttonWord}>取消</div>
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="提醒"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>名片無法發送，請檢查名片內容有無不符合規定地方</p>
      </Modal>

      <Modal
        title="提醒"
        open={isDeleteModalOpen.show}
        onCancel={cancelDeleteModalHandle}
        footer={false}
      >
        <div className={classes.deleteModal}>
          <p>是否確定要刪除此電子名片</p>
          <div>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={okDeleteModalHandle}
            >
              <div className={classes.buttonWord}>確定刪除</div>
            </Button>
            <Button
              className={classes.button}
              type="primary"
              ghost
              onClick={cancelDeleteModalHandle}
            >
              <div>取消</div>
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法編輯名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>

      <Modal
        title="修改權益限制提醒"
        open={isUpdateBanModalOpen}
        onOk={UpdateBanHandleOk}
        onCancel={UpdateBanHandleCancel}
      >
        <p>無法修改名片，（您非此款名片的設計者）喔！</p>
      </Modal>

      <Modal
        title="刪除權益限制提醒"
        open={isDeleteBanModalOpen}
        onOk={DeleteBanHandleOk}
        onCancel={DeleteBanHandleCancel}
        footer={
          (currentNameCardData && currentNameCardData.unlinkEnable === 1 && (
            <Button
              onClick={() =>
                nameCardUnlinkMe(currentNameCardData?.card_id || null, {
                  delete_mobiles: userState.mobile_number,
                })
              }
            >
              unlink me
            </Button>
          )) ||
          (currentNameCardData && currentNameCardData.removeEnable === 1 && (
            <Button
              onClick={() =>
                nameCardRemoveMe(currentNameCardData?.card_id || null)
              }
            >
              remove me
            </Button>
          ))
        }
      >
        <p>無法刪除名片，（您非此款名片的擁有者）喔！</p>
      </Modal>

      <Modal
        title="私密名片分享限制提醒"
        open={isShareBanModalOpen}
        onOk={ShareBanHandleOk}
        onCancel={ShareBanHandleCancel}
      >
        <p>不得分享私密名片喔！</p>
      </Modal>

      <Modal
        title="私密名片分享權限提醒"
        open={isPrivateShareBanModalOpen}
        onOk={PrivateShareBanHandleOk}
        onCancel={PrivateShareBanHandleCancel}
      >
        <p>您沒有權限分享私密名片喔！</p>
      </Modal>

      <Modal
        title="新手會員提醒"
        open={isHintModalOpen}
        onOk={HintHandleOk}
        onCancel={HintHandleCancel}
      >
        <p>
          歡迎您加入數位名片設計行列，請您先觸按右上角「三條線」按鈕後再觸按「十」按鈕，
          進入「新增名片」頁面輸入名片資料並上傳圖片，
          開始享受親自設計製作名片的樂趣，謝謝！
        </p>
      </Modal>

      <Modal
        title="會員二度提醒"
        open={isHint2ModalOpen}
        onOk={Hint2HandleOk}
        onCancel={Hint2HandleCancel}
      >
        <p>
          再次歡迎您加入數位名片設計行列，煩請您觸按右上角「三條線」按鈕，
          進入「個人資料」頁面輸入包含手機號碼等基本資料，
          以利後續您的數位名片設計製作並分享，謝謝！
        </p>
      </Modal>

      <Modal
        title="聯盟行銷說明"
        open={isHint3ModalOpen}
        onOk={Hint3HandleOk}
        onCancel={Hint3HandleCancel}
      >
        <p>
          再次歡迎您加入數位名片設計行列，當您成為我們的正式付費會員之後，
          就有機會加入我們的聯盟行銷夥伴團隊，每一訂單可享第一層分潤25%以及第二層分潤12.5%，
          可在個人資料頁面下方點按〈付費解鎖進階功能〉成為正式會員，
          若您不想再看到此頁訊息，請進入「個人資料」頁面輸入包含手機號碼等基本資料，
          以解除此頁面的顯示連結，感謝您！
        </p>
      </Modal>
    </Fragment>
  );
};
export default NameCardsList;
