import { Col, Form, Input, Row, Button } from "antd";
import classes from "./NameCardButton.module.sass";
import isValidHttpUrl from "../../../../utils/isValidHttpUrl";
import {
  nameCardV1DataType,
  useAppStateStore,
  buttonType,
} from "../../../../store/appState";
import {
  IphoneButton,
  IShareNameCardButton,
  IurlButton,
} from "../../../../interface/flexMessage";
import { useEffect } from "react";

type NameCardButtonType = "urlButton" | "phoneButton" | "shareNameCardButton";

type NameCardButtonProps = {
  id: string;
  elementIndex: number;
  thisPageFlexMessageIndex: number;
  buttonType: NameCardButtonType;
};

const NameCardButton = ({
  id,
  elementIndex,
  thisPageFlexMessageIndex,
  buttonType,
}: NameCardButtonProps) => {
  const { NAMECARD_DATA } = useAppStateStore();
  const { nameCardData, setNameCardV1Data } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Form } = nameCardData;

  // 驗證規則
  const validationRules = {
    buttonText: [
      {
        required: true,
        validator: async (_: unknown, value: string) => {
          if (!value) {
            return Promise.reject("請輸入按鈕文字");
          }
          // 檢查是否包含任何空白字元
          if (/\s/.test(value)) {
            return Promise.reject("按鈕文字不可包含空白字元");
          }
          return Promise.resolve();
        },
      },
    ],
    urlButton: [
      {
        validator: async (_: unknown, value: string) => {
          if (!value) {
            return Promise.reject("請輸入連結");
          }
          if (!isValidHttpUrl(value)) {
            return Promise.reject("請輸入有效的URL");
          }
          return Promise.resolve();
        },
      },
    ],
    phoneButton: [
      {
        validator: async (_: unknown, value: string) => {
          if (!value) return Promise.reject("請輸入電話號碼");

          // 移除可能的前綴以進行驗證
          const phoneNumber = value.replace(/^(\+886|0)/, "");

          // 台灣手機: 9開頭後面8碼
          const mobilePattern = /^9\d{8}$/;
          // 市話: 區碼1-2碼 + 電話號碼6-8碼
          const telPattern = /^[2-8]\d{7,8}$/;
          // 0800服務電話: 移除0後為800
          const servicePattern = /^800\d{6}$/;

          if (
            !mobilePattern.test(phoneNumber) &&
            !telPattern.test(phoneNumber) &&
            !servicePattern.test(phoneNumber)
          ) {
            return Promise.reject("請輸入有效的台灣電話號碼格式");
          }

          return Promise.resolve();
        },
      },
    ],
  };

  /**
   * 處理超連結：按鈕 URL 的變更
   * @param url 連結
   */
  const handleUrlChange = (url: string) => {
    if (!nameCardV1Data) return;
    console.log("url", url);

    const _flexMessage = Array.from(nameCardV1Data);
    const targetButton =
      _flexMessage[thisPageFlexMessageIndex].button[elementIndex];

    (targetButton.content as IurlButton).buttonUrl = url;

    setNameCardV1Data(_flexMessage);
  };

  /**
   * 處理手機號碼的變更
   * @param phone 手機號碼
   */
  const handlePhoneChange = (phone: string) => {
    if (!nameCardV1Data) return;

    const _flexMessage = Array.from(nameCardV1Data);
    const targetButton =
      _flexMessage[thisPageFlexMessageIndex].button[elementIndex];

    (targetButton.content as IphoneButton).phoneNumber = phone;

    setNameCardV1Data(_flexMessage);
  };

  /**
   * 處理按鈕值的變更
   * @param value 按鈕值
   * @param buttonType 按鈕類型
   */
  const handleNameCardButtonValueChange = (
    id: string,
    value: string,
    buttonType: NameCardButtonType
  ) => {
    console.log("onChange id", id);
    if (buttonType === "shareNameCardButton") return;
    if (buttonType === "urlButton") {
      handleUrlChange(value);
    } else if (buttonType === "phoneButton") {
      handlePhoneChange(value);
    }
  };

  /**
   * 處理按鈕文字的變更
   * @param text 按鈕文字
   */
  const handleTextChange = (_text: string) => {
    if (nameCardV1Data) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      const _content = _flexMessage[thisPageFlexMessageIndex].button[
        elementIndex
      ].content as IurlButton;
      _content.buttonText = _text;
      _flexMessage[thisPageFlexMessageIndex].button[elementIndex].content =
        _content;

      setNameCardV1Data(_flexMessage);
    }
  };

  /**
   * 移除按鈕
   * @param index 按鈕索引
   */
  const remove = (_index: number) => {
    if (nameCardV1Data) {
      const _flexMessage: nameCardV1DataType[] = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndex].button.splice(_index, 1);
      // 重新設定剩餘按鈕的 id
      _flexMessage[thisPageFlexMessageIndex].button = _flexMessage[
        thisPageFlexMessageIndex
      ].button.map((button, idx) => ({
        ...button,
        id: idx,
        content: {
          ...button.content,
          id: idx,
        },
      })) as buttonType[];

      setNameCardV1Data(_flexMessage);
    }
  };

  /**
   * 設定按鈕表單值
   * @param buttonType 按鈕類型
   * @param buttonContent 按鈕內容
   * @param id 按鈕 ID
   * @param elementIndex 按鈕索引
   */
  const setButtonFormValues = (
    buttonType: "urlButton" | "phoneButton" | "shareNameCardButton",
    buttonContent: any,
    id: string,
    elementIndex: number
  ) => {
    console.log("按鈕內容變化:", {
      buttonType,
      elementIndex,
      content: buttonContent,
      formValues: nameCardV1Form[id]?.getFieldsValue(),
    });

    const formValues = {
      urlButton: {
        [`buttonText-${id}-${elementIndex}`]: buttonContent.buttonText,
        [`buttonUrl-${id}-${elementIndex}`]: (buttonContent as IurlButton)
          .buttonUrl,
      },
      phoneButton: {
        [`phoneNumber-${id}-${elementIndex}`]: (buttonContent as IphoneButton)
          .phoneNumber,
      },
      shareNameCardButton: {
        [`shareNameCardButtonText-${id}-${elementIndex}`]:
          buttonContent.buttonText,
        [`shareNameCardButtonUrl-${id}-${elementIndex}`]: (
          buttonContent as IShareNameCardButton
        ).buttonUrl,
      },
    };

    nameCardV1Form[id]?.setFieldsValue(formValues[buttonType]);
  };

  useEffect(() => {
    if (!nameCardV1Data) return;
    if (thisPageFlexMessageIndex === undefined) return;
    if (elementIndex >= nameCardV1Data[thisPageFlexMessageIndex].button.length)
      return;
    if (!nameCardV1Form) return;
    if (!id) return;
    const buttonContent =
      nameCardV1Data[thisPageFlexMessageIndex].button[elementIndex].content;

    setButtonFormValues(buttonType, buttonContent, id, elementIndex);
  }, [
    nameCardV1Data,
    elementIndex,
    buttonType,
    nameCardV1Form,
    id,
    thisPageFlexMessageIndex,
  ]);

  const currentData = nameCardV1Data.find((data) => data.id === id);

  useEffect(() => {
    console.log("當前 Tab ID:", id);
    console.log("當前 Tab 數據:", currentData);
  }, [id, currentData]);

  return (
    <Row justify="center" align="middle" gutter={[8, 8]}>
      <Col span={24}>
        <div className={classes.content}>
          <div className={classes.col}>
            {/* 按鈕文字 */}
            {buttonType !== "phoneButton" && (
              <Form.Item
                name={
                  buttonType === "shareNameCardButton"
                    ? `shareNameCardButtonText-${id}-${elementIndex}`
                    : `buttonText-${id}-${elementIndex}`
                }
                label={
                  <span className={classes.label}>
                    按鈕 {elementIndex + 1} 文字
                  </span>
                }
                rules={validationRules.buttonText}
              >
                <Input
                  placeholder={`請輸入按鈕 ${elementIndex + 1} 文字`}
                  disabled={buttonType === "shareNameCardButton"}
                  onChange={(e) => handleTextChange(e.target.value)}
                />
              </Form.Item>
            )}

            {/* 按鈕連結/電話/分享名片連結 */}
            <Form.Item
              name={
                buttonType === "urlButton"
                  ? `buttonUrl-${id}-${elementIndex}`
                  : buttonType === "shareNameCardButton"
                    ? `shareNameCardButtonUrl-${id}-${elementIndex}`
                    : `phoneNumber-${id}-${elementIndex}`
              }
              label={
                <span className={classes.label}>
                  {buttonType === "urlButton"
                    ? `按鈕 ${elementIndex + 1} 連結`
                    : buttonType === "shareNameCardButton"
                      ? `分享名片連結`
                      : `電話號碼`}
                </span>
              }
              rules={
                buttonType === "phoneButton"
                  ? validationRules.phoneButton
                  : validationRules.urlButton
              }
            >
              <Input
                placeholder={
                  buttonType === "urlButton"
                    ? "請輸入連結"
                    : buttonType === "shareNameCardButton"
                      ? "請輸入分享名片連結"
                      : "請輸入手機號碼"
                }
                type={buttonType === "phoneButton" ? "tel" : "text"}
                disabled={buttonType === "shareNameCardButton"}
                onChange={(e) => {
                  console.log("e.target.value", e.target.value);
                  handleNameCardButtonValueChange(
                    id,
                    e.target.value,
                    buttonType
                  );
                }}
              />
            </Form.Item>
          </div>

          <div>
            <Button
              size="small"
              className={classes.button}
              onClick={() => remove(elementIndex)}
            >
              X
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NameCardButton;
