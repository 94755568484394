import { create } from "zustand";
import { getCurrentConfig } from "../utils/config";
import { devtools } from "zustand/middleware";
import { CFlexBubble } from "../../flexMessageType";
import { FormInstance } from "antd";

type currntConfigType = {
  REACT_APP_API_URL: string;
  REACT_APP_ENV: string;
  REACT_APP_VIP_URL: string;
  REACT_APP_LIFFID: string;
  REACT_APP_IMAGEPATH: string;
  REACT_APP_IMAGEBASEURL: string;
  REACT_APP_COUPON_IMAGE: string;
};

type LiffStateType = {
  lineIsLoggedIn: boolean;
  isInClient: boolean;
  lineIDToken: string | null;
  lineAccessToken: string | null;
  ready: boolean;
};
type ipFlowStateType = {
  isLogin: boolean;
  isEagle: boolean;
  role: 1 | 2;
};

type appStateType = {
  isLoading: boolean;
  isError: boolean;
  errorMsg: string | null;
  errorCode: number | null;
};

type appType = {
  currentConfig: currntConfigType;
  liffState: LiffStateType;
  setLiffState: (newLiffState: Partial<LiffStateType>) => void;
  ipFlowState: ipFlowStateType;
  setIpFlowState: (newIpFlowState: Partial<ipFlowStateType>) => void;
  appState: appStateType;
  setAppState: (newAppState: Partial<appStateType>) => void;
};

// 24.11.01 SCH ★
type brandStateType = {
  id: 0; // 24.11.30 SCH ★
  accessToken: string | null;
  liff_id_promo: string | null; // 24.11.21 SCH ★
  trial_name_cards: 0;
  trial_months: 0;
  trial_login_times: 0;
  use_name_card_count: 0;
  use_trial_due_at: 0;
  use_login_log_count: 0;
  login_log_count: 0;
  otp_auth: 0 | 1 | 2 | 3; // 24.11.30 SCH ★
  auth_code_length: 0; // 24.11.30 SCH ★
  trial_name_card3: 0; // 25.01.02 SCH ★
  name_card_page_1: 0; // 25.01.27 SCH ★ // 1: 一般會員「名片頁數」上限
  name_card_page_2: 0; // 25.01.27 SCH ★ // 2: 正式會員「名片頁數」上限
  ad_content_v1: string | null; // 25.02.12 SCH ★ 數位名片(圖文版編輯器v1)的廣告頁JSON
  ad_content_v2: string | null; // 25.02.12 SCH ★ 數位名片(設計版編輯器v2)的廣告頁JSON
  ad_content_v3: string | null; // 25.02.12 SCH ★ 數位名片(滿頁版編輯器v3)的廣告頁JSON
  editor_url: string | null; // 25.02.12 SCH ★ 電子名片編輯器超連結網址
  edu_url: string | null; // 25.02.12 SCH ★ 電子名片教學的超連結網址
  web_url: string | null; // 25.02.12 SCH ★ 電子名片官網的超連結網址
  language: string | null; // 25.02.23 SCH ★ 語系 default: tw
  currency: string | null; // 25.02.23 SCH ★ 幣別 default: NTD
  categoryEnable: 0 | 1; // 25.02.24 SCH ★ 是否可編輯名片類別？
  privateShareEnable: 0 | 1; // 25.03.02 SCH ★ 是否有私密分享的權限？
  phoneLength: number; // 25.02.24 SCH ★ 手機號碼長度？(default=10)
  role3Friends: number; // 25.02.25 SCH ★ (role=3=「銅級」會員, 朋友圈人數:<=300)
  role4Friends: number; // 25.02.25 SCH ★ (role=4=「銀級」會員, 朋友圈人數:<=6000)
  role5Friends: number; // 25.02.25 SCH ★ (role=5=「金級」會員, 朋友圈人數:<=150000)
};

export type userStateType = {
  user_id: number | null;
  accessToken: string | null;
  expires_in: string;
  is_normal: boolean;
  mobile_number: string;
  // 一般用戶 1 正式用戶 0
  is_common: 0 | 1;
  // 已經登入為 1 尚未登入為 0
  isLoggedIn: 0 | 1;
  // 已經是會員 1 不是會員 0
  isMember: 0 | 1;
  // 已經有紀錄 1 尚未紀錄 0
  logging: 0 | 1;
  // 是老鷹推廣夥伴 1 | 不是老鷹推廣夥伴 0
  eagle: 0 | 1; // (1=是老鷹)
  line_id: string;
  nickname: string;
  realname: string;
  brand_id: number;
  regkey: string | null;
  regkey_expire_at: string | null;
  // 非付費會員  1 |  付費會員 2
  role: 1 | 2;
  type: string;
  // 性別：0 : 女、1 : 男 、2 : 其他 // 24.10.31 SCH
  gender: string | null;
  address: string | null;
  birthday: string | null;
  email: string;
  photo_url: string | null;
  enable: 0 | 1; // 24.10.31 SCH (1=可新增)
  editable: 0 | 1; // 24.10.31 SCH (1=可修改)
  isDesigner: number; // 25.02.24 SCH ★ (1=設計師)
  isPaidMember: number; // 25.02.25 SCH ★ (1=付費會員)
  expired_at: string | null;
  login_at: string | null;
  logout_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  pay_amount: number | null;
  disconnect_line: 0 | 1;
  uid: string | null;
  line_pay: 0 | 1;
  promoter_uid: string | null; // 24.11.04 SCH
  promoter_mobile: string | null;
  promo_hash: string | null;
  promo_slug: string | null;
  promoter_id2: string | null;
  promoter_mobile2: string | null;
  bank_code: string | null; // 24.10.31 SCH
  bank_account: string | null; // 24.10.31 SCH
  name_card_count: number; // 24.10.31 SCH
  trial_due_at: string | null; // 24.10.31 SCH
  coupon_code: string | null;
};

type profileInuptType = {
  realname: string | undefined;
  email: string | undefined;
  mobile_number: string | undefined;
  promo_slug: string | undefined;
  bank_code: string | undefined;
  bank_account: string | undefined;
  isValidatePhoneNumber: boolean;
  isValidateEmail: boolean;
  isValidateName: boolean;
  isValidatePromoSlug: boolean;
  isValidateBankCode: boolean;
  isValidateBankAccount: boolean;
  isValidateAll: boolean; // 24.11.06 SCH
};

type CurrentCategoryType = {
  user_category_id: number | null;
  category_name: string | null;
  user_folder_id: number | null;
  folder_name: string | null;
};

// 24.11.01 SCH ★
type brandType = {
  brandState: brandStateType;
  setBrandState: (newBrandState: Partial<brandStateType>) => void;
};

type userType = {
  userState: userStateType;
  setUserState: (newUserState: Partial<userStateType>) => void;
};

type profilePageType = {
  profileInputData: profileInuptType;
  setProfileInputData: (profileInputData: Partial<profileInuptType>) => void;
  initProfileInputData: () => void;
};

// 定義頁面模式的聯合類型
export type pageModeType =
  | "nameCardsList"
  | "profile"
  | "createNameCard"
  | "updateNameCard"
  | "createNameCardFullImgMode"
  | "updateNameCardFullImgMode"
  | "userCheck"
  | "userBonus"
  | "customCategories"
  | "userCoupon"
  | "registerPartnersAccount"
  | "applyPrivateSharePermission"
  | "null";

// 定義頁面模式的類型
export type currentPageModeType = {
  currentPageModeState: pageModeType;
  setCurrentPageModeState: (
    newCurrentPageModeState: Partial<pageModeType>
  ) => void;
};

export type nameCardOperationStateType = {
  action:
    | "create"
    | "update"
    | "reCreate"
    | "goProfile"
    | "goUserBonus"
    | "goNameCardList"
    | "goCustomCategories"
    | "goRegisterPartnersAccount"
    | "goApplyPrivateSharePermission"
    | "null";
  currentNameCardType: "v1.0" | "v2.0" | "v3.0" | "null";
  selectNameCardType: "v1.0" | "v2.0" | "v3.0" | "null";
};

type nameCardOperationType = {
  nameCardOperationState: nameCardOperationStateType;
  setNameCardOperationState: (
    newNameCardOperationState: Partial<nameCardOperationStateType>
  ) => void;
};

// 24.11.04 SCH ★
type userBonusStateType = {
  y4m2: string | undefined;
  date_start: string | undefined;
  date_end: string | undefined;
  isUserBonus: boolean; // 24.11.05 SCH ★
};

// 24.11.04 SCH ★
type userBonusType = {
  userBonusState: userBonusStateType;
  setUserBonusState: (newUserBonusState: Partial<userBonusStateType>) => void;
};

export type buttonType =
  | {
      type: "urlButton";
      content: {
        _key: string;
        buttonText: string;
        buttonUrl: string;
        id: number;
      };
      _key: string;
      id: number;
    }
  | {
      type: "phoneButton";
      content: {
        _key: string;
        buttonText: string;
        phoneNumber: string;
        buttonUrl: string; // 25.03.04 SCH ★
        id: number;
      };
      _key: string;
      id: number;
    }
  | {
      type: "shareNameCardButton";
      content: {
        _key: string;
        buttonText: string;
        buttonUrl: string;
        id: number;
      };
      _key: string;
      id: number;
    };

export type nameCardV1DataType = {
  id: string;
  IntroText: { _key: string; id: number; inputValue: string }[];
  imageUrl: {
    _key: string;
    imageUrl: string;
  }[];
  button: buttonType[];
  title: string;
  titleColor: string;
  introTextColor: string;
  cardBackgroundColor: string;
};

export type fontSizeType =
  | "xxs"
  | "sx"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "xxl"
  | "3xl"
  | "4xl"
  | "5xl";

export type nameCardV1SettingType = {
  altText: string;
  cardTitle: string;
  titleFontSize: fontSizeType;
  describeFontSize: fontSizeType;
};
export type nameCardV3SettingType = {
  cardTitle: string;
  altText: string;
};

export const initNameCardV1Data: nameCardV1DataType = {
  id: "newTab-1",
  IntroText: [{ _key: "newTab-1", id: 0, inputValue: "說明文字內容(預設)" }],
  imageUrl: [
    {
      _key: "newTab-1",
      imageUrl: "",
    },
  ],
  // 25.03.04 SCH ★ set default = ('https://liff.line.me/'+liff_id)？
  // 25.03.04 SCH ★ (main liff_id = 1657460954-qbmy6dAr)
  // 25.03.04 SCH ★ set default value for buttonUrl& Text
  // AlterNameCardV1Data(); // 25.03.04 SCH ★★★★★
  // liff_id_promo ?? "https://liff.line.me/1657460954-qbmy6dAr";
  // old default value for buttonUrl = "https://line.me/zh-hant/"
  button: [
    {
      _key: "newTab-1",
      id: 0,
      content: {
        buttonText: "按鈕文字(可編輯)",
        buttonUrl: "https://line.me/zh-hant/",
        id: 0,
        _key: "newTab-1",
      },
      type: "urlButton",
    },
  ],
  title: "姓名",
  titleColor: "#000000",
  introTextColor: "#000000",
  cardBackgroundColor: "#ffffff",
};

const initNameCardV1Setting: nameCardV1SettingType = {
  altText: "",
  cardTitle: "",
  titleFontSize: "xl",
  describeFontSize: "lg",
};

const initNameCardV3Data: CFlexBubble = {
  type: "bubble",
  body: {
    type: "box",
    layout: "vertical",
    contents: [],
    paddingAll: "0px",
  },
};

const initNameCardV3Setting: nameCardV3SettingType = {
  altText: "",
  cardTitle: "",
};

const initCurrentCategoryData: CurrentCategoryType = {
  user_category_id: null,
  category_name: null,
  user_folder_id: null,
  folder_name: null,
};

type nameCardDataType = {
  nameCardV1Data: nameCardV1DataType[];
  nameCardV1CurrentActiveKey: string;
  nameCardV1Setting: nameCardV1SettingType;
  nameCardV1Form: { [key: string]: FormInstance }; // 使用 tab id 作為 key
  nameCardV3Form: FormInstance | null;
  nameCardV2Data: null;
  nameCardV3Data: CFlexBubble[];
  nameCardV3CurrentPage: number;
  nameCardV3Setting: nameCardV3SettingType;
  currentCategory: CurrentCategoryType;
};

type memberCheckType = {
  isMemberCheck: boolean;
  setIsMemberCheck: (_isMemberCheck: boolean) => void;
};

type nameCardContentDataType = {
  nameCardData: nameCardDataType;
  setNameCardV1Data: (newNameCardV1Data: nameCardV1DataType[]) => void;
  setNameCardV1Setting: (
    newNameCardV1Setting: Partial<nameCardV1SettingType>
  ) => void;
  setNameCardV1CurrentActiveKey: (
    newNameCardV1CurrentActiveKey: string
  ) => void;
  setNameCardV1Form: (tabId: string, form: FormInstance) => void;
  setNameCardV3Form: (form: FormInstance) => void;
  removeNameCardV1Form: (tabId: string) => void;
  setNameCardV2Data: (newNameCardV2Data: null) => void;
  setNameCardV3Data: (newNameCardV3Data: CFlexBubble[]) => void;
  setNameCardV3Setting: (
    newNameCardV3Setting: Partial<nameCardV3SettingType>
  ) => void;
  setNameCardV3CurrentPage: (newNameCardV3CurrentPage: number) => void;
  initNameCardV1Data: () => void;
  initNameCardV1Setting: () => void;
  initNameCardV3Data: () => void;
  initNameCardV3Setting: () => void;
  setCurrentCategory: (newCurrentCategory: CurrentCategoryType) => void;
  initCurrentCategory: () => void;
  initNameCardV1Form: () => void;
  initNameCardV3Form: () => void;
};

type bodyBlockRefType = {
  bodyBlockRef: HTMLDivElement | null;
  setbBdyBlockRef: (_bodyBlockRef: HTMLDivElement) => void;
};

type NavListHeaderType = {
  isOpen: boolean;
  toggle: () => void;
  close: () => void;
};

export type useAppStateStoreType = {
  APP: appType;
  BRAND: brandType; // 24.11.01 SCH ★
  USER: userType;
  PROFILE_PAGE: profilePageType;
  PAGEMODE: currentPageModeType;
  NAMECARD_OPERATION: nameCardOperationType;
  NAMECARD_DATA: nameCardContentDataType;
  MEMBERCHECK: memberCheckType;
  BODYBLOCKREF: bodyBlockRefType;
  USER_BONUS: userBonusType; // 24.11.04 SCH ★
  NavListHeader: NavListHeaderType; // 新增 nav 狀態
};

export const useAppStateStore = create<useAppStateStoreType>()(
  devtools(
    // persist(
    (set, get) => ({
      APP: {
        currentConfig: getCurrentConfig(),
        liffState: {
          lineAccessToken: null,
          lineIDToken: null,
          isInClient: false,
          lineIsLoggedIn: false,
          ready: false,
        },
        setLiffState: (newLiffState: Partial<LiffStateType>) =>
          set((state) => ({
            APP: {
              ...state.APP,
              liffState: {
                ...state.APP.liffState,
                ...newLiffState,
              },
            },
          })),
        ipFlowState: {
          isLogin: false,
          isEagle: false,
          role: 1,
        },
        setIpFlowState: (newIpFlowState) =>
          set((state) => ({
            ...state,
            ipFlowState: {
              ...state.APP.ipFlowState,
              ...newIpFlowState,
            },
          })),
        appState: {
          isLoading: false,
          isError: false,
          errorMsg: null,
          errorCode: null,
        },
        setAppState: (newAppState) =>
          set((state) => ({
            ...state,
            APP: {
              ...state.APP,
              appState: {
                ...state.APP.appState,
                ...newAppState,
              },
            },
          })),
      },
      BRAND: {
        brandState: {
          id: 0,
          accessToken: null,
          liff_id_promo: null,
          trial_name_cards: 0,
          trial_months: 0,
          trial_login_times: 0,
          use_name_card_count: 0,
          use_trial_due_at: 0,
          use_login_log_count: 0,
          login_log_count: 0,
          otp_auth: 0,
          auth_code_length: 0,
          trial_name_card3: 0,
          name_card_page_1: 0,
          name_card_page_2: 0,
          ad_content_v1: null,
          ad_content_v2: null,
          ad_content_v3: null,
          editor_url: null,
          edu_url: null,
          web_url: null,
          language: null,
          currency: null,
          categoryEnable: 0,
          privateShareEnable: 0,
          phoneLength: 0,
          role3Friends: 0,
          role4Friends: 0,
          role5Friends: 0,
        },
        setBrandState: (newBrandState) =>
          set((state) => ({
            ...state,
            BRAND: {
              ...state.BRAND,
              brandState: {
                ...state.BRAND.brandState,
                ...newBrandState,
              },
            },
          })),
      },
      USER: {
        userState: {
          user_id: null,
          accessToken: null,
          expires_in: "",
          is_normal: true,
          mobile_number: "",
          // 一般用戶 1 正式用戶 0
          is_common: 0,
          // 已經登入為 1 尚未登入為 0
          isLoggedIn: 0,
          // 已經是會員 1 不是會員 0
          isMember: 0,
          // 已經有紀錄 1 尚未紀錄 0
          logging: 0,
          // 是老鷹推廣夥伴 1 | 不是老鷹推廣夥伴 0
          eagle: 0, // (1=是老鷹)
          line_id: "",
          nickname: "",
          realname: "",
          brand_id: 1,
          regkey: null,
          regkey_expire_at: null,
          // 非付費會員  1 |  付費會員 2
          role: 1,
          type: "",
          // 性別：0 : 女、1 : 男 、2 : 其他 // 24.10.31 SCH
          gender: null,
          address: "",
          birthday: null,
          email: "",
          photo_url: null,
          enable: 0, // 24.10.31 SCH (1=可新增)
          editable: 0, // 24.10.31 SCH (1=可修改)
          isDesigner: 0, // 25.02.24 SCH (1=設計師)
          isPaidMember: 0, // 25.02.25 SCH ★ (1=付費會員)
          expired_at: null,
          login_at: null,
          logout_at: null,
          created_at: "",
          updated_at: "",
          pay_amount: 0,
          disconnect_line: 0,
          uid: null,
          line_pay: 0,
          promoter_uid: null, // 24.11.04 SCH
          promoter_mobile: null,
          promo_hash: null,
          promo_slug: null,
          promoter_id2: null,
          promoter_mobile2: null,
          bank_code: null, // 24.10.31 SCH
          bank_account: null, // 24.10.31 SCH
          name_card_count: 0, // 24.10.31 SCH
          trial_due_at: null, // 24.10.31 SCH
          coupon_code: null,
        },
        setUserState: (newUserState) =>
          set((state) => ({
            ...state,
            USER: {
              ...state.USER,
              userState: {
                ...state.USER.userState,
                ...newUserState,
              },
            },
          })),
      },
      PROFILE_PAGE: {
        profileInputData: {
          realname: undefined,
          email: undefined,
          mobile_number: undefined,
          promo_slug: undefined,
          bank_code: undefined,
          bank_account: undefined,
          isValidatePhoneNumber: false,
          isValidateEmail: false,
          isValidateName: false,
          isValidatePromoSlug: false,
          isValidateBankCode: false,
          isValidateBankAccount: false,
          isValidateAll: true, // 24.11.06 SCH
        },
        setProfileInputData: (newProfileInputData) =>
          set((state) => ({
            ...state,
            PROFILE_PAGE: {
              ...state.PROFILE_PAGE,
              profileInputData: {
                ...state.PROFILE_PAGE.profileInputData,
                ...newProfileInputData,
              },
            },
          })),
        initProfileInputData: () =>
          set((state) => ({
            ...state,
            PROFILE_PAGE: {
              ...state.PROFILE_PAGE,
              profileInputData: {
                ...state.PROFILE_PAGE.profileInputData,
                realname: "",
                email: "",
                mobile_number: "",
                promo_slug: "",
                isValidatePhoneNumber: false,
                isValidateEmail: false,
                isValidateName: false,
                isValidatePromoSlug: false,
              },
            },
          })),
      },
      PAGEMODE: {
        currentPageModeState: "null",
        setCurrentPageModeState: (newCurrentPageModeState) =>
          set((state) => ({
            ...state,
            PAGEMODE: {
              ...state.PAGEMODE,
              currentPageModeState: newCurrentPageModeState,
            },
          })),
      },
      NAMECARD_OPERATION: {
        nameCardOperationState: {
          action: "create",
          currentNameCardType: "null",
          selectNameCardType: "null",
        },
        setNameCardOperationState: (newNameCardOperationState) =>
          set((state) => ({
            ...state,
            NAMECARD_OPERATION: {
              ...state.NAMECARD_OPERATION,
              nameCardOperationState: {
                ...state.NAMECARD_OPERATION.nameCardOperationState,
                ...newNameCardOperationState,
              },
            },
          })),
      },
      NAMECARD_DATA: {
        nameCardData: {
          nameCardV1Data: [JSON.parse(JSON.stringify(initNameCardV1Data))],
          nameCardV1Setting: JSON.parse(JSON.stringify(initNameCardV1Setting)),
          nameCardV1Form: {},
          nameCardV1CurrentActiveKey: "newTab-1",
          nameCardV3Form: null,
          nameCardV2Data: null,
          nameCardV3Data: [JSON.parse(JSON.stringify(initNameCardV3Data))],
          nameCardV3CurrentPage: 0,
          nameCardV3Setting: JSON.parse(JSON.stringify(initNameCardV3Setting)),
          currentCategory: {
            user_category_id: null,
            category_name: null,
            user_folder_id: null,
            folder_name: null,
          },
        },
        setNameCardV1Data: (newNameCardV1Data: nameCardV1DataType[]) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Data: newNameCardV1Data,
              },
            },
          })),
        initNameCardV1Data: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Data: [
                  JSON.parse(JSON.stringify(initNameCardV1Data)),
                ],
              },
            },
          })),
        initNameCardV1Setting: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Setting: JSON.parse(
                  JSON.stringify(initNameCardV1Setting)
                ),
              },
            },
          })),
        setNameCardV1Setting: (newNameCardV1Setting) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Setting: {
                  ...state.NAMECARD_DATA.nameCardData.nameCardV1Setting,
                  ...newNameCardV1Setting,
                },
              },
            },
          })),
        setNameCardV1Form: (tabId: string, newNameCardV1Form: FormInstance) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Form: {
                  ...state.NAMECARD_DATA.nameCardData.nameCardV1Form,
                  [tabId]: newNameCardV1Form,
                },
              },
            },
          })),

        removeNameCardV1Form: (tabId: string) =>
          set((state) => {
            const newForms = {
              ...state.NAMECARD_DATA.nameCardData.nameCardV1Form,
            };
            delete newForms[tabId];

            return {
              ...state,
              NAMECARD_DATA: {
                ...state.NAMECARD_DATA,
                nameCardData: {
                  ...state.NAMECARD_DATA.nameCardData,
                  nameCardV1Form: newForms,
                },
              },
            };
          }),
        setNameCardV1CurrentActiveKey: (
          newNameCardV1CurrentActiveKey: string
        ) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1CurrentActiveKey: newNameCardV1CurrentActiveKey,
              },
            },
          })),
        setNameCardV3Form: (form: FormInstance) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Form: form,
              },
            },
          })),

        setNameCardV2Data: (newNameCardV2Data) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV2Data: newNameCardV2Data,
              },
            },
          })),

        setNameCardV3Data: (newNameCardV3Data: CFlexBubble[]) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Data: newNameCardV3Data,
              },
            },
          })),
        setNameCardV3CurrentPage: (newNameCardV3CurrentPage: number) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3CurrentPage: newNameCardV3CurrentPage,
              },
            },
          })),
        initNameCardV3Data: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Data: [
                  JSON.parse(JSON.stringify(initNameCardV3Data)),
                ],
              },
            },
          })),
        initNameCardV3Setting: () =>
          set((state) => ({
            ...state,
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Setting: JSON.parse(
                  JSON.stringify(initNameCardV3Setting)
                ),
              },
            },
          })),
        setNameCardV3Setting: (newNameCardV3Setting) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Setting: {
                  ...state.NAMECARD_DATA.nameCardData.nameCardV3Setting,
                  ...newNameCardV3Setting,
                },
              },
            },
          })),
        setCurrentCategory: (newCurrentCategory: CurrentCategoryType) =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                currentCategory: {
                  ...state.NAMECARD_DATA.nameCardData.currentCategory,
                  ...newCurrentCategory,
                },
              },
            },
          })),
        initCurrentCategory: () =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                currentCategory: initCurrentCategoryData,
              },
            },
          })),
        initNameCardV1Form: () =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV1Form: {},
              },
            },
          })),
        initNameCardV3Form: () =>
          set((state) => ({
            NAMECARD_DATA: {
              ...state.NAMECARD_DATA,
              nameCardData: {
                ...state.NAMECARD_DATA.nameCardData,
                nameCardV3Form: null,
              },
            },
          })),
      },
      MEMBERCHECK: {
        isMemberCheck: false,
        setIsMemberCheck: (_isMemberCheck: boolean) =>
          set((state) => ({
            MEMBERCHECK: {
              ...state.MEMBERCHECK,
              isMemberCheck: _isMemberCheck,
            },
          })),
      },
      BODYBLOCKREF: {
        bodyBlockRef: null,
        setbBdyBlockRef: (_bodyBlockRef: HTMLDivElement) =>
          set((state) => ({
            BODYBLOCKREF: {
              ...state.BODYBLOCKREF,
              bodyBlockRef: _bodyBlockRef,
            },
          })),
      },
      USER_BONUS: {
        userBonusState: {
          y4m2: undefined,
          date_start: undefined,
          date_end: undefined,
          isUserBonus: false,
        },
        setUserBonusState: (newUserBonusState) =>
          set((state) => ({
            ...state,
            USER_BONUS: {
              ...state.USER_BONUS,
              userBonusState: {
                ...state.USER_BONUS.userBonusState,
                ...newUserBonusState,
              },
            },
          })),
      },
      NavListHeader: {
        isOpen: false,
        toggle: () =>
          set((state) => ({
            ...state,
            NavListHeader: {
              ...state.NavListHeader,
              isOpen: !state.NavListHeader.isOpen,
            },
          })),
        close: () =>
          set((state) => ({
            ...state,
            NavListHeader: { ...state.NavListHeader, isOpen: false },
          })),
      },
    }),
    {
      name: "IPFlow-storage",
      getStorage: () => sessionStorage, // 配置本地存储，可以选择 sessionStorage 或其他存储方式
    }
  )
  // )
);
