/**
 * 檢查是否為有效的 HTTP URL
 * @param url 要檢查的 URL
 * @returns 如果 URL 是有效的 HTTP URL 或 mailto 協議的 URL ，則返回 true，否則返回 false
 */
const isValidHttpUrl = (_url: string) => {
  try {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const url = new URL(_url);

    // 支援 mailto 協議
    if (url.protocol === "mailto:") {
      return emailRegex.test(url.pathname);
    }

    if (url.protocol === "http:" || url.protocol === "https:") {
      let domain = _url.split("/")[2];
      domain = domain.split(":")[0];
      var reg =
        /^(.+\.)(com|edu|gov|int|mil|net|org|biz|info|name|museum|coop|aero|[a-z][a-z])/;
      if (reg.test(domain)) {
        return true;
      } else {
        return false;
      }
    }
  } catch (err) {
    return false;
  }
};

export default isValidHttpUrl;
