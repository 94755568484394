import { Button, Form, Input, Modal, message } from "antd";
import classes from "./Profile.module.sass";
import { useEffect, useState } from "react";
import Loading from "../../Loading";
import ClipboardJS from "clipboard";
import { CopyTwoTone } from "@ant-design/icons";
import { useAppStateStore } from "../../../store/appState";
import { useNameCardFromValidate } from "../../../context/NameCardFromValidate";
import { queryPlanData, planType } from "../../../api/index"; // 24.11.14 SCH ★

import isMobileNumberChange from "../../../utils/isMobileNumberChange";
import isMemberProfileFulfilled from "../../../utils/isMemberProfileFulfilled"; // 25.02.07 SCH ★
import PaymentModal from "./PaymentModal";

type inputHandelerType = {
  key: "realname" | "mobile_number" | "email" | "promo_slug" | string;
  value: string;
};

const Profile = () => {
  const [isValidatePhoneNumber, setIsValidatePhoneNumber] =
    useState<boolean>(false);
  const [isValidateEmail, setIsValidateEmail] = useState<boolean>(false);
  const [isValidateName, setIsValidateName] = useState<boolean>(false);
  const [isValidatePromoSlug, setIsValidatePromoSlug] =
    useState<boolean>(false);
  const [isValidateBankCode, setIsValidateBankCode] = useState<boolean>(false);
  const [isValidateBankAccount, setIsValidateBankAccount] =
    useState<boolean>(false);
  const [payOpenModal, setPayOpenModal] = useState<boolean>(false);

  const [isFieldBanModalOpen, setIsFieldBanModalOpen] = useState(false); // 24.11.07 SCH ★
  const { isValidateAll } = useNameCardFromValidate(); // 24.11.07 SCH ★
  const [planList, setPlanList] = useState<planType[]>(); // 24.11.14 SCH ★

  const { USER, APP, PROFILE_PAGE, PAGEMODE } = useAppStateStore();
  const { userState } = USER;
  const { appState, currentConfig } = APP;
  const { setProfileInputData, profileInputData } = PROFILE_PAGE;
  const { setCurrentPageModeState } = PAGEMODE;
  const { accessToken } = userState; // 24.11.14 SCH ★

  // 電子郵件格式
  const emailRule =
    /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{3,3}$/;

  // 手機號碼格式
  const phoneNumberRule = /^09\d{8}$/;

  // 姓名只能包含字母、中文、數字
  const nameRule = /^[a-zA-Z\u4E00-\u9FA5\d]+$/;

  // 推廣碼格式
  const promoSlugRule = /^[a-zA-Z0-9_-]{1,20}$/;

  // 銀行代碼格式
  const bankCodeRule = /^[0-9]{3,5}$/;

  // 銀行帳號格式
  const bankAccountRule = /^[0-9]{11,14}$/;

  /**
   * 驗證欄位
   * @param key - 欄位名稱
   * @param value - 欄位值
   * @param rule - 驗證規則
   * @param setState - 設定狀態的函數
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validateField = (
    key: string,
    value: string,
    rule: RegExp,
    setState: (val: boolean) => void
  ): boolean => {
    const isValid = rule.test(value);
    setState(isValid);
    setProfileInputData({ [key]: isValid });
    return isValid;
  };

  /**
   * 驗證手機號碼
   * @param value - 要驗證的手機號碼
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validatePhoneNumber = (value: string) => {
    return validateField(
      "isValidatePhoneNumber",
      value,
      phoneNumberRule,
      setIsValidatePhoneNumber
    );
  };

  /**
   * 驗證電子郵件
   * @param value - 要驗證的電子郵件
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validateEmail = (value: string) => {
    return validateField(
      "isValidateEmail",
      value,
      emailRule,
      setIsValidateEmail
    );
  };

  /**
   * 驗證姓名
   * @param value - 要驗證的姓名
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validateName = (value: string) => {
    return validateField("isValidateName", value, nameRule, setIsValidateName);
  };

  /**
   * 驗證推廣碼
   * @param value - 要驗證的推廣碼
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validatePromoSlug = (value: string) => {
    console.log("value.length :", value.trim().length);
    if (value.trim().length > 20) {
      setIsFieldBanModalOpen(true);
    }
    return validateField(
      "isValidatePromoSlug",
      value,
      promoSlugRule,
      setIsValidatePromoSlug
    );
  };

  /**
   * 驗證銀行代碼
   * @param value - 要驗證的銀行代碼
   * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
   */
  const validateBankCode = (value: string) => {
    return validateField(
      "isValidateBankCode",
      value,
      bankCodeRule,
      setIsValidateBankCode
    );
  };

  // /**
  //  * 驗證銀行帳號
  //  * @param value - 要驗證的銀行帳號
  //  * @returns {boolean} - 如果驗證通過返回 true，否則返回 false
  //  */
  const validateBankAccount = (value: string) => {
    return validateField(
      "isValidateBankAccount",
      value,
      bankAccountRule,
      setIsValidateBankAccount
    );
  };

  // 24.11.14 SCH ★
  const getPlanData = async () => {
    try {
      if (accessToken) {
        const { data: res } = await queryPlanData(accessToken);
        if (res) {
          const { data } = res;
          console.log("after getPlanData", data);
          setPlanList(data);
        }
      }
    } catch (error) {
      console.log("after getPlanData", error); // 24.11.14 SCH ★
    }
  };

  const inputHandeler = ({ key, value }: inputHandelerType) => {
    switch (key) {
      case "realname":
        validateName(value) && setProfileInputData({ [key]: value });
        break;
      case "mobile_number":
        validatePhoneNumber(value) && setProfileInputData({ [key]: value });
        break;
      case "email":
        validateEmail(value) && setProfileInputData({ [key]: value });
        break;
      case "promo_slug":
        validatePromoSlug(value) && setProfileInputData({ [key]: value });
        break;
      case "bank_code":
        validateBankCode(value) && setProfileInputData({ [key]: value });
        break;
      case "bank_account":
        validateBankAccount(value) && setProfileInputData({ [key]: value });
        break;
      default:
        break;
    }
  };

  const handleCopy = (
    _willCopyClass: string,
    successMsg: string,
    errorMsg: string
  ) => {
    const clipboard = new ClipboardJS(_willCopyClass);
    clipboard.on("success", () => {
      message.success(`${successMsg}`);
    });
    clipboard.on("error", () => {
      message.error(`${errorMsg}`);
    });
  };

  const payOpenModalCancel = () => {
    setPayOpenModal(false);
  };

  const FieldBanHandleOk = () => {
    setIsFieldBanModalOpen(false);
  };

  const FieldBanHandleCancel = () => {
    setIsFieldBanModalOpen(false);
  };

  useEffect(() => {
    if (userState) {
      const {
        mobile_number,
        email,
        realname,
        promo_slug,
        bank_code,
        bank_account,
      } = userState;

      (mobile_number || mobile_number === "") &&
        validatePhoneNumber(mobile_number);
      (email || email === "") && validateEmail(email);
      (realname || realname === "") && validateName(realname);
      promo_slug && validatePromoSlug(promo_slug);
      bank_code && validateBankCode(bank_code);
      bank_account && validateBankAccount(bank_account);

      let initData = {}; // 24.11.07 SCH ★
      if (isValidateAll) {
        initData = {
          mobile_number: profileInputData.mobile_number,
          email: profileInputData.email,
          realname: profileInputData.realname,
          promo_slug: profileInputData.promo_slug,
          bank_code: profileInputData.bank_code,
          bank_account: profileInputData.bank_account,
        };
      } else {
        // 24.11.07 SCH ★ updateMe() 失敗後放回原輸入資料
        initData = {
          mobile_number: mobile_number,
          email: email,
          realname: realname,
          promo_slug: promo_slug,
          bank_code: bank_code,
          bank_account: bank_account,
          isValidateAll: true, // 24.11.07 SCH ★
        };
      }
      setProfileInputData({ ...initData });
    }
  }, [userState]);

  useEffect(() => {
    getPlanData(); // 24.11.14 SCH ★
    setCurrentPageModeState("profile");
  }, [userState]);

  return (
    <>
      {appState.isLoading ? (
        <Loading />
      ) : (
        <div className={classes.container}>
          <Form className={classes.contents} layout="vertical">
            <Form.Item
              label={<span>會員姓名</span>}
              validateStatus={isValidateName ? "success" : "error"}
              hasFeedback
            >
              <Input
                type="text"
                name="realname"
                placeholder="會員姓名"
                defaultValue={userState.realname ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              label={<span>聯絡電話</span>}
              validateStatus={isValidatePhoneNumber ? "success" : "error"}
              hasFeedback
            >
              <Input
                maxLength={10}
                name="mobile_number"
                placeholder="聯絡電話"
                defaultValue={userState.mobile_number ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              label={<span>Email</span>}
              validateStatus={isValidateEmail ? "success" : "error"}
              hasFeedback
            >
              <Input
                type="email"
                name="email"
                placeholder="Email"
                defaultValue={userState.email ?? ""}
                onChange={(e) => {
                  inputHandeler({ key: e.target.name, value: e.target.value });
                }}
              ></Input>
            </Form.Item>
            {userState.role > 1 && (
              <div>
                <Form.Item
                  label={<span> 推廣連結 (自訂) - 推廣碼</span>}
                  validateStatus={isValidatePromoSlug ? "success" : "error"}
                  hasFeedback
                >
                  <Input
                    type="text"
                    name="promo_slug"
                    placeholder="輸入您想要自訂的推廣碼"
                    defaultValue={userState.promo_slug ?? ""}
                    onChange={(e) => {
                      inputHandeler({
                        key: e.target.name,
                        value: e.target.value,
                      });
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={<span> 佣金入帳銀行代碼 </span>}
                  validateStatus={isValidateBankCode ? "success" : "error"}
                  hasFeedback
                >
                  <Input
                    type="text"
                    name="bank_code"
                    placeholder="輸入您的佣金入帳銀行代碼"
                    defaultValue={userState.bank_code ?? ""}
                    maxLength={5}
                    onChange={(e) => {
                      inputHandeler({
                        key: e.target.name,
                        value: e.target.value,
                      });
                    }}
                  ></Input>
                </Form.Item>

                <Form.Item
                  label={<span> 佣金入帳銀行帳號 </span>}
                  validateStatus={isValidateBankAccount ? "success" : "error"}
                  hasFeedback
                >
                  <Input
                    type="text"
                    name="bank_account"
                    placeholder="輸入您的佣金入帳銀行帳號"
                    defaultValue={userState.bank_account ?? ""}
                    maxLength={14}
                    onChange={(e) => {
                      inputHandeler({
                        key: e.target.name,
                        value: e.target.value,
                      });
                    }}
                  ></Input>
                </Form.Item>
              </div>
            )}
            <Form.Item label={<span>會員等級</span>}>
              <span className={classes.memberText}>
                {userState.role !== 1 ? "付費會員" : "一般會員"}
                {userState.eagle ? " (eagle)" : ""}
              </span>
            </Form.Item>

            <Form.Item label={<span>推廣上線</span>}>
              <div>
                <span className={classes.memberText}>
                  推廣上線會員編號 :{" "}
                  {userState.promoter_uid ? userState.promoter_uid : "無"}
                </span>
              </div>
              <div>
                <span className={classes.memberText}>
                  推廣上線手機號碼 :{" "}
                  {userState.promoter_mobile ? userState.promoter_mobile : "無"}
                </span>
              </div>
            </Form.Item>
            {userState.role > 1 && (
              <div>
                {userState?.promo_hash && (
                  <Form.Item label={<span>推廣連結 (預設)</span>}>
                    <div>
                      <a
                        href={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      >
                        {`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      </a>

                      <CopyTwoTone
                        style={{ fontSize: "20px", marginLeft: "1rem" }}
                        rev={""}
                        onClick={() =>
                          handleCopy(
                            ".copy-btn-promoHash",
                            "推廣連結 (預設) 複製成功!",
                            "推廣連結 (預設) 複製失敗!"
                          )
                        }
                        className="copy-btn-promoHash"
                        data-clipboard-text={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ph=${userState?.promo_hash}`}
                      />
                    </div>
                  </Form.Item>
                )}
                {userState.promo_slug && (
                  <Form.Item label={<span>推廣連結 (自訂)</span>}>
                    <div>
                      <a
                        href={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      >
                        {`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      </a>

                      <CopyTwoTone
                        style={{ fontSize: "20px", marginLeft: "1rem" }}
                        rev={""}
                        onClick={() =>
                          handleCopy(
                            ".copy-btn-promoSlug",
                            "推廣連結 (自訂) 複製成功!",
                            "推廣連結 (自訂) 複製失敗!"
                          )
                        }
                        className="copy-btn-promoSlug"
                        data-clipboard-text={`https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/?ps=${userState.promo_slug}`}
                      />
                    </div>
                  </Form.Item>
                )}
              </div>
            )}
            <Form.Item className={classes.payBtn}>
              <Button
                onClick={() => {
                  if (
                    isMobileNumberChange(
                      profileInputData.mobile_number,
                      userState.mobile_number
                    )
                  ) {
                    return message.warn(
                      "手機號碼有變更，請先通過手機驗證，並先按下儲存按鈕儲存個人資料，再進行付費解鎖功能，謝謝您",
                      5
                    );
                  }
                  if (
                    !isMemberProfileFulfilled(
                      profileInputData.mobile_number,
                      profileInputData.realname,
                      profileInputData.email,
                      userState.mobile_number,
                      userState.realname,
                      userState.email
                    )
                  ) {
                    return message.warn(
                      "請先補齊個人基本資料，然後按下儲存按鈕，通過手機驗證之後，再進行付費解鎖功能，謝謝您",
                      5
                    ); // 25.02.07 SCH ★
                  }
                  if (userState.role === 2)
                    return message.warning("已經購買付費方案，方案尚未到期", 5); // 24.12.01 SCH ★
                  setPayOpenModal(true);
                }}
              >
                付費解鎖進階功能
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      <PaymentModal
        open={payOpenModal}
        onCancel={payOpenModalCancel}
        planList={planList}
        userState={userState}
        profileInputData={profileInputData}
        isValidatePhoneNumber={isValidatePhoneNumber}
        isValidateEmail={isValidateEmail}
      />

      <Modal
        title="欄位編輯限制提醒"
        open={isFieldBanModalOpen}
        onOk={FieldBanHandleOk}
        onCancel={FieldBanHandleCancel}
      >
        <p>您自訂的推廣碼長度太長喔！</p>
      </Modal>
    </>
  );
};

export default Profile;
